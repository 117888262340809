import React, { lazy } from "react";
import {
  Home,
  Product,
  Confirmation,
  Account,
  Cart,
  Payment,
  Tracker,
  Checkout,
  ConfirmTransfer,
  Category,
  ProductsCanonical,
  ConfirmInvoicePayment,
  BankTransfer,
  WishList,
  WishListCart,
  WishListCheckout,
  WishListPayment,
  SantaSales,
} from "../buyer-page";

import {
  NewProducts,
  Overview,
  HomeDashboard,
  Orders,
  Settings,
  Payments,
  Customers,
  AddProduct,
  SingleProduct,
  CompleteSetup,
  UpgradePlan,
  VerifyPremiumPayment,
  SocialPages,
  Plan,
  SocialProducts,
  Profile,
  Discounts,
  StoreSetting,
  Invoices,
  CreateInvoice,
  EditInvoice,
  OrderDetail,
  InvoiceDetail,
  Wallet,
} from "pages/App";
import Coupon from "pages/App/Coupon";
import MyPurchases from "pages/App/MyPurchases";
import MyPurchaseDetail from "pages/App/MyPurchaseDetail";

const Logout = lazy(() => import("pages/Logout"));
const UpdatePassword = lazy(() => import("pages/UpdatePassword"));
const VerifyEmail = lazy(() => import("pages/VerifyEmail"));
const AmbassadorHomePage = lazy(() => import("pages/AmbassadorHomePage"));

const IncomingPayment = lazy(() =>
  import("pages/VendorPayment/IncomingPayment")
);
const TrackPayment = lazy(() => import("pages/VendorPayment/TrackPayment"));
const PaymentConfirmation = lazy(() =>
  import("pages/VendorPayment/PaymentConfirmation")
);
const ConfirmInvoicePage = lazy(() => import("pages/ConfirmInvoicePage"));

const CustomersPageWithOnBoarding = lazy(() =>
  import("pages/CustomersPageWithOnBoarding")
);
const ProductPageWithOnBoarding = lazy(() =>
  import("pages/ProductPageWithOnBoarding")
);
const WelcomePage = lazy(() => import("pages/WelcomePage"));
const PrivacyPage = lazy(() => import("pages/PrivacyPage"));
const ProductPolicy = lazy(() => import("pages/ProductPolicy"));
const AmlPolicy = lazy(() => import("pages/AmlPolicy"));
const TermsAndConditionPage = lazy(() => import("pages/TermsAndConditionPage"));

const KlumpCheckoutPage = lazy(() => import("pages/KlumpCheckoutPage"));
const ForexInvoicePage = lazy(() => import("pages/ForexInvoicePage"));
const ConfirmPremiumPaymentPage = lazy(() =>
  import("pages/ConfirmPremiumPaymentPage")
);

const ConfirmationPage = lazy(() => import("pages/ConfirmationPage"));
const ConfirmCheckoutPage = lazy(() => import("pages/ConfirmCheckoutPage"));
const InvoicePaymentConfirmationPage = lazy(() =>
  import("pages/InvoicePaymentConfirmationPage")
);
const ThirdParty = lazy(() => import("pages/ThirdParty"));

// New Dashboard starts here.
const Products = lazy(() => import("pages/DashboardPages/Products"));
const Support = lazy(() => import("pages/DashboardPages/Support"));
const Company = lazy(() => import("pages/DashboardPages/Company"));
const Pricing = lazy(() => import("pages/DashboardPages/Pricing"));
// const CampusAmbassedor = lazy(() => import("pages/CampusAmbassadorPage"));

const premium = lazy(() => import("pages/PremiumPage"));
const Escrow = lazy(() => import("pages/DashboardPages/Escrow"));
const newDashboardLogin = lazy(() => import("pages/DashboardPages/Login"));
const newDashboardRegister = lazy(() =>
  import("pages/DashboardPages/Register")
);
const newDashboardForgetPassword = lazy(() =>
  import("pages/DashboardPages/ForgotPassword")
);
const newDashboardConfirmPassword = lazy(() =>
  import("pages/DashboardPages/ConfirmPassword")
);
const newDashboardResetPassowrd = lazy(() =>
  import("pages/DashboardPages/ResetPassword")
);

const LandingPage = lazy(() => import("pages/LandingPage"));

const WaitListSuccess = lazy(() => import("pages/WaitListSuccess"));
const RefererPage = lazy(() => import("pages/RefererPage"));
const StoreSetupPage = lazy(() => import("pages/StoreSetupPage"));

const InitiateAuth = lazy(() => import("pages/VendorPayment/InitiateAuth"));
const Dashboard = lazy(() => import("pages/App/Dashboard"));

const routes = [
  // new Dashboard starts here

  {
    path: "/dashboard",
    //exact: true,
    component: Dashboard,
    isProtected: false,
  },
  {
    path: "/premium",
    exact: true,
    component: premium,
    isProtected: false,
  },
  // {
  //   path: "/campus-ambassador",
  //   exact: true,
  //   component: CampusAmbassedor,
  //   isProtected: false,
  // },
  // {
  //   path: "/category/:id",
  //   exact: true,
  //   component: Category,
  //   isProtected: false,
  // },

  {
    path: "/upgrade/confirm-payment",
    exact: true,
    component: ConfirmPremiumPaymentPage,
    isProtected: true,
  },
  {
    path: "/",
    exact: true,
    component: newDashboardLogin,
    isProtected: false,
  },
  {
    path: "/register",
    exact: true,
    component: newDashboardRegister,
    isProtected: false,
  },
  {
    path: "/store-setup",
    exact: true,
    component: StoreSetupPage,
    isProtected: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    component: newDashboardForgetPassword,
    isProtected: false,
  },
  {
    path: "/verify-email/:verifyToken",
    exact: true,
    component: VerifyEmail,
    isProtected: false,
  },
  {
    path: "/confirm-password",
    exact: true,
    component: newDashboardConfirmPassword,
    isProtected: false,
  },
  {
    path: "/reset_password/:token",
    exact: true,
    component: newDashboardResetPassowrd,
    isProtected: false,
  },
  // {
  //   path: "/escrow",
  //   exact: true,
  //   component: Escrow,
  //   isProtected: false,
  // },

  {
    path: "/welcome",
    exact: true,
    component: WelcomePage,
    isProtected: false,
  },
  // {
  //   path: "/privacy",
  //   exact: true,
  //   component: PrivacyPage,
  //   isProtected: false,
  // },

  {
    path: "/referral",
    exact: true,
    component: RefererPage,
    isProtected: false,
  },

  {
    path: "/update-password",
    exact: true,
    component: UpdatePassword,
    isProtected: false,
  },

  {
    path: "/vendor/incoming-payment",
    // exact: false,
    component: IncomingPayment,
    isProtected: false,
  },
  {
    path: "/vendor/track-payment",
    // exact: false,
    component: TrackPayment,
    isProtected: false,
  },
  {
    path: "/vendor/payment-confirmation",
    // exact: false,
    component: PaymentConfirmation,
    isProtected: false,
  },

  {
    path: "/vendor/track-payment",
    // exact: false,
    component: TrackPayment,
    isProtected: false,
  },
  {
    path: "/vendor/payment-confirmation",
    // exact: false,
    component: PaymentConfirmation,
    isProtected: false,
  },
  {
    path: "/vendor/initiate-auth",
    exact: true,
    component: InitiateAuth,
    isProtected: false,
  },
  {
    path: "/ambassador",
    // exact: false,
    component: AmbassadorHomePage,
    isProtected: true,
  },
  {
    path: "/customers-onboarding",
    exact: true,
    component: CustomersPageWithOnBoarding,
    isProtected: true,
  },

  {
    path: "/products-onboarding",
    exact: true,
    component: ProductPageWithOnBoarding,
    isProtected: true,
  },

  {
    path: "/confirmcheckout",
    exact: true,
    component: ConfirmCheckoutPage,
    isProtected: true,
  },

  // {
  //   path: "/premium",
  //   exact: true,
  //   component: premium,
  //   isProtected: false,
  // },
  // {
  //   path: "/campus-ambassador",
  //   exact: true,
  //   component: CampusAmbassedor,
  //   isProtected: false,
  // },

  // {
  //   path: "/upgrade",
  //   exact: true,
  //   component: UpgradePage,
  //   isProtected: true,
  // },
  // {
  //   path: "/wait-list",
  //   exact: true,
  //   component: RefererPage,
  //   isProtected: false,
  // },
  // {
  //   path: "/wait-list/success",
  //   exact: true,
  //   component: WaitListSuccess,
  //   isProtected: false,
  // },
  {
    path: "/upgrade/confirm-payment",
    exact: true,
    component: ConfirmPremiumPaymentPage,
    isProtected: true,
  },
  {
    path: "/confirm-password",
    exact: true,
    component: newDashboardConfirmPassword,
    isProtected: false,
  },
  {
    path: "/reset_password/:token",
    exact: true,
    component: newDashboardResetPassowrd,
    isProtected: false,
  },
  // {
  //   path: "/escrow",
  //   exact: true,
  //   component: Escrow,
  //   isProtected: false,
  // },
  // {
  //   path: "/products",
  //   exact: true,
  //   component: Products,
  //   isProtected: false,
  // },
  // {
  //   path: "/aboutUs",
  //   exact: true,
  //   component: Company,
  //   isProtected: false,
  // },
  // {
  //   path: "/support",
  //   exact: true,
  //   component: Support,
  //   isProtected: false,
  // },
  // {
  //   path: "/pricing",
  //   exact: true,
  //   component: Pricing,
  //   isProtected: false,
  // },

  // {
  //   path: "/terms-condition",
  //   exact: true,
  //   component: TermsAndConditionPage,
  //   isProtected: false,
  // },

  {
    path: "/logout",
    exact: true,
    component: Logout,
    isProtected: false,
  },

  {
    path: "/ambassador",
    // exact: false,
    component: AmbassadorHomePage,
    isProtected: true,
  },

  // {
  //   path: "/customers",
  //   exact: true,
  //   component: CustomersPage,
  //   isProtected: true,
  // },
  // {
  //   path: "/customers-onboarding",
  //   exact: true,
  //   component: CustomersPageWithOnBoarding,
  //   isProtected: true,
  // },

  // {
  //   path: "/products-onboarding",
  //   exact: true,
  //   component: ProductPageWithOnBoarding,
  //   isProtected: true,
  // },

  {
    path: "/confirm",
    exact: true,
    component: ConfirmationPage,
    isProtected: true,
  },
  {
    path: "/confirmcheckout",
    exact: true,
    component: ConfirmCheckoutPage,
    isProtected: true,
  },
  {
    path: "/forex-invoice",
    exact: true,
    component: ForexInvoicePage,
    isProtected: true,
  },

  {
    path: "/confirm-invoice/:invoiceID",
    exact: true,
    component: ConfirmInvoicePage,
    isProtected: false,
  },
  {
    path: "/klump-checkout",
    exact: false,
    component: KlumpCheckoutPage,
    isProtected: true,
  },
  {
    path: "/invoice-payment-confirm",
    exact: false,
    component: InvoicePaymentConfirmationPage,
    isProtected: true,
  },
  {
    path: "/api/thirdParty/orderTranx/getOrderTranx",
    exact: false,
    component: ThirdParty,
    isProtected: false,
  },
  {
    path: "/products-policy",
    exact: true,
    component: ProductPolicy,
    isProtected: false,
  },
  {
    path: "/aml-cft-policy",
    exact: true,
    component: AmlPolicy,
    isProtected: false,
  },

  // buyers routing
  // {
  //   path: "/marketplace",
  //   exact: false,
  //   component: SantaSales,
  //   isProtected: false,
  // },
  // {
  //   path: "/store/:merchantID",
  //   exact: false,
  //   component: Home,
  //   isProtected: false,
  // },
  // {
  //   path: "/product/:id",
  //   exact: true,
  //   component: Product,
  //   isProtected: false,
  // },
  // {
  //   path: "/cart",
  //   exact: true,
  //   component: Cart,
  //   isProtected: false,
  // },
  {
    path: "/invoice",
    exact: true,
    component: Checkout,
    isProtected: false,
  },
  {
    path: "/invoice/confirm-transfer",
    exact: true,
    component: ConfirmTransfer,
    isProtected: false,
  },
  {
    path: "/invoice/pay",
    exact: true,
    component: BankTransfer,
    isProtected: false,
  },
  {
    path: "/invoice/confirm-payment/:orderID",
    exact: true,
    component: ConfirmInvoicePayment,
    isProtected: false,
  },
  {
    path: "/payment",
    exact: true,
    component: Payment,
    isProtected: false,
  },
  {
    path: "/account",
    exact: true,
    component: Account,
    isProtected: false,
  },
  {
    path: "/tracker/:id",
    exact: true,
    component: Tracker,
    isProtected: false,
  },
  {
    path: "/confirmation",
    exact: true,
    component: Confirmation,
    isProtected: false,
  },
  {
    path: "/allProducts",
    exact: true,
    component: ProductsCanonical,
    isProtected: false,
  },
  // {
  //   path: "/wishlist",
  //   exact: true,
  //   component: WishList,
  //   isProtected: false,
  // },
  // {
  //   path: "/wishlist/cart",
  //   exact: true,
  //   component: WishListCart,
  //   isProtected: false,
  // },
  // {
  //   path: "/wishlist/checkout",
  //   exact: true,
  //   component: WishListCheckout,
  //   isProtected: false,
  // },
  // {
  //   path: "/wishlist/payment",
  //   exact: true,
  //   component: WishListPayment,
  //   isProtected: false,
  // },
  // {
  //   path: "/",
  //   exact: true,
  //   component: LandingPage,
  //   isProtected: false,
  // },
];

export default routes;

export const dashboardRoutes = [
  {
    path: "/dashboard/coupons",
    exact: true,
    component: Coupon,
    isPremium: true,
  },
  {
    path: "/dashboard/discounts",
    exact: true,
    component: Discounts,
    isPremium: true,
  },
  {
    path: "/dashboard/overview",
    exact: true,
    component: Overview,
    isPremium: false,
  },
  {
    path: "/dashboard/home",
    exact: true,
    component: HomeDashboard,
    isPremium: false,
  },
  // {
  //   path: "/dashboard/wallet",
  //   exact: true,
  //   component: Wallet,
  //   isPremium: false,
  // },
  {
    path: "/dashboard/payments",
    exact: true,
    component: Payments,
    isPremium: false,
  },
  {
    path: "/dashboard/products",
    exact: true,
    component: NewProducts,
    isPremium: false,
  },
  {
    path: "/dashboard/plan",
    exact: true,
    component: Plan,
    isPremium: false,
  },
  {
    path: "/dashboard/profile",
    exact: true,
    component: Profile,
    isPremium: false,
  },
  {
    path: "/dashboard/orders",
    exact: true,
    component: Orders,
    isPremium: false,
  },

  {
    path: "/dashboard/orders/:orderRef",
    exact: true,
    component: OrderDetail,
    isPremium: false,
  },
  {
    path: "/dashboard/mypurchases",
    exact: true,
    component: MyPurchases,
    isPremium: false,
  },
  {
    path: "/dashboard/mypurchases/:orderRef",
    exact: true,
    component: MyPurchaseDetail,
    isPremium: false,
  },

  {
    path: "/dashboard/customers",
    exact: true,
    component: Customers,
    isPremium: false,
  },
  {
    path: "/dashboard/invoices",
    exact: true,
    isPremium: true,
    component: Invoices,
  },
  {
    path: "/dashboard/invoices/new",
    exact: true,
    component: CreateInvoice,
    isPremium: true,
  },
  {
    path: "/dashboard/invoices/:invoiceID",
    exact: true,
    component: EditInvoice,
    isPremium: true,
  },
  {
    path: "/dashboard/invoices/detail/:invoiceID",
    exact: true,
    component: InvoiceDetail,
    isPremium: true,
  },
  {
    path: "/dashboard/settings",
    exact: true,
    component: Settings,
    isPremium: false,
  },
  {
    path: "/dashboard/store-setting",
    exact: true,
    component: StoreSetting,
  },
  {
    path: "/dashboard/social-pages",
    exact: true,
    component: SocialPages,
    isPremium: false,
  },
  {
    path: "/dashboard/social-products",
    exact: true,
    component: SocialProducts,
    isPremium: false,
  },
  {
    path: "/dashboard/complete-setup",
    exact: true,
    component: CompleteSetup,
    isPremium: false,
  },
  {
    path: "/dashboard/products/new",
    exact: true,
    component: AddProduct,
    isPremium: false,
  },
  {
    path: "/dashboard/products/:productId",
    exact: true,
    component: SingleProduct,
    isPremium: false,
  },

  {
    path: "/dashboard/upgrade-plan",
    exact: true,
    component: UpgradePlan,
    isPremium: false,
  },
  {
    path: "/dashboard/verify-payment",
    exact: true,
    component: VerifyPremiumPayment,
    isPremium: false,
  },
];
