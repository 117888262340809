import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Wallet,
  WalletErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { setAddress } from "./orders";
import axios from "axios";
import { setAlert } from "./alert";
import { notify } from "libs/utils";
import { trackEvent } from "libs/utils/customer-io";


export const withdrawFund = (data) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.WITHDRAW_FUND });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.post(Wallet.WITHDRAW_WALLET_FUND, data, { headers })
      .then((response) => {
        console.log({ response });
        //dispatch(getMerchantStoreProducts(userInfo.merchantCode));
        dispatch({ type: actionTypes.WITHDRAW_FUND_SUCCESS });
        dispatch(setAlert("Fund withdrawn successfully", "success"));
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.WITHDRAW_FUND_FAIL,
          error: message,
        });
      });
  };
};



export const getWalletHistory = (type) => {
    return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
      dispatch({
        type: actionTypes.GET_WALLET_HISTORY,
      });
      const headers = {
        Authorization: token,
        customerID: userInfo.customerID,
      };
      const params = {type}
      PepperestAxios.get(Wallet.GET_WALLET_HISTORY, {params,headers })
        .then((response) => {
          const  {walletHistory}  = response.data;
          dispatch({
            type: actionTypes.GET_WALLET_HISTORY_SUCCESS,
            walletHistory,
          });
        })
        .catch((error) => {
          console.error("wallet history error",error.response);
          //dispatch(setAlert("An error occurred", "error"));
        });
    };
  };

  export const getWallet = () => {
    return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
      dispatch({
        type: actionTypes.GET_WALLET,
      });
      const headers = {
        Authorization: token,
        customerID: userInfo.customerID,
      };
      PepperestAxios.get(Wallet.GET_WALLET, { headers })
        .then((response) => {
          const { wallet } = response.data;
          dispatch({
            type: actionTypes.GET_WALLET_SUCCESS,
            wallet,
          });
        })
        .catch((error) => {
          console.error("wallet error",error.response);
          //dispatch(setAlert("An error occurred", "error"));
        });
    };
  };

  export const addTransactionPin = (data) => {
    return (dispatch, getState) => {
      const { token} = getState().auth;
      dispatch({ type: actionTypes.ADD_TRANSACTION_PIN });
      const headers = {
        Authorization: token,
      };
  
      PepperestAxios.post(Wallet.ADD_TRANSACTION_PIN, data, { headers })
        .then((response) => {
          console.log({ response });
          dispatch({ type: actionTypes.ADD_TRANSACTION_PIN_SUCCESS });
          trackEvent("wallet_create_pin_successful")
          dispatch(setAlert("Your transaction pin has been added successfully", "success"));
        })
        .catch((error) => {
          const message = error?.response?.data
            ? error.response?.data?.ResponseMessage
            : "Could not connect to server";
          dispatch(setAlert(error.response?.data?.ResponseMessage, "error"))
          trackEvent("wallet_create_pin_fail")
          dispatch({
            type: actionTypes.ADD_TRANSACTION_PIN_FAIL,
            error: message,
          });
         
        });
    };
  };

  export const validateTransactionPin = (data) => {
    return (dispatch, getState) => {
      const { token} = getState().auth;
      dispatch({ type: actionTypes.VALIDATE_TRANSACTION_PIN });
      const headers = {
        Authorization: token,
      };
  
      PepperestAxios.post(Wallet.VALIDATE_TRANSACTION_PIN, data, { headers })
        .then((response) => {
          console.log({ response });
          dispatch({ type: actionTypes.VALIDATE_TRANSACTION_PIN_SUCCESS });
        })
        .catch((error) => {
          const message = error?.response?.data
            ? error.response?.data?.ResponseMessage
            : "Could not connect to server";
          dispatch({
            type: actionTypes.VALIDATE_TRANSACTION_PIN_FAIL,
            error: message,
          });
        });
    };
  };
  

