import React from "react";
import { currencyFormatter2 } from "store/utility";

const OverviewCard = ({ title, itemCount, type, icon }) => {
  return (
    <div className={`metric__card`}>
      <div className="icon__container">
        <img src={`/assets/images/${icon}`} className="card__icon" />
      </div>
      <div className="card__content">
        <p className="card__title">{title}</p>
        <p className="item__count">
          {type == "revenues"
            ? `₦${currencyFormatter2.format(itemCount ? itemCount : 0)}`  
            : itemCount}
        </p>
      </div>
    </div>
  );
};

export default OverviewCard;
{
  /* <div className={`metric__card ${type}__bg`}> */
}