import React from "react";
// import { FooterList } from "components/blocks";
// import { withWelcomeLayout } from "components/layouts";
// import { getStringHash } from "libs/utils";
// import { footerLinks } from "config/inner-routes";
import { useHistory } from "react-router-dom";
import "../../index.css";

import Footer from "components/newDashboardComponents/Footer/Footer";
import NavBar from "components/landingPageComponent/NavBar";
import CallToAction from "components/landingPageComponent/CallToAction";
import { MdDiversity1 } from "react-icons/md";

const config = {
  hasAlternateHeader: false,
  hasCommonHeader: true,
  showCart: false,
  commonHeaderTitle: "peppa.io Privacy Policy",
  links: [],
  page: "welcome",
  isSettings: true,
  navBarTitle: "Welcome",
};
const AmlPolicy = () => {
  const history = useHistory();

  return (
    <div className="bg-white landing__page">
      <div style={{ position: "absolute", width: "100%", zIndex: 1, top: "0", left: "0" }}>
        <NavBar />
      </div>
      <div className="onboarding_page container px-3 ">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="onboarding-card">
              {/* <div className="onboarding-header" style={{ marginTop: "15px" }}>
                <h3>Privacy Policy</h3>
              </div> */}

              <div
                className="onboarding-header pageTitle text-center"
                style={{ margin: "4rem 0 2rem 0" }}
              >
                <h3 className="font-weight-bold">Anti-Money Laundering and Countering the Financing of Terrorism Policy</h3>
                <p className="text-center">Effective 1st January, 2024</p>
              </div>
              <div className="main-content">
                <div className="paragraph-item">
                   <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                      1. Introduction
                    </h2>
                  <p className="paragraph">
                  Pepper Rest Ltd (the “Company”, “us”, “we”) is a private company limited
                   by shares registered under the laws of England and Wales and providing
                    payment services to merchants and vendors through its e-commerce and payment 
                    services technology platform. The business of the Company might be a risk in 
                    relation to money laundering, however, in order to prevent any of our services
                     being used (or potentially used) for any money laundering activity, as well as
                      any of our staff being exposed to money laundering, we wish to put in place the
                       following anti-money laundering policy which supplements the anti-money
                    laundering, combating the financing of terrorism, and countering proliferation
                     financing training given to all members of staff.
                     </p>
                  
                     <br />
                     <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                     2.	Definitions & Interpretations
                    </h2>
                  <p className="paragraph">
                     <div style={{ marginTop: "10px",marginLeft:"30px"}}>
                        <p className="row mb-3">
                            <span className="col-md-4" style={{ fontWeight: "bold" }}>CTR </span> <p className="col-md-8">means Currency Transaction Reports.</p>
                        </p>
                        
                        <p className="row mb-3">
                            <span className="col-md-4" style={{ fontWeight: "bold" }}>FATF </span> <p className="col-md-8">means Financial Action Task Force.</p>
                        </p>
                        <p className="row mb-3">
                            <span className="col-md-4" style={{ fontWeight: "bold" }}>MLRO </span> <p className="col-md-8"> means Money Laundering Reporting Officer.</p>
                        </p>
                        <p className="row mb-3">
                            <span className="col-md-4" style={{ fontWeight: "bold" }}>MLR 2017 </span> <p className="col-md-8"> means Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 (MLR 2017) as amended by the Money Laundering and Terrorist Financing (Amendment) Regulations 2019.</p>
                        </p>
                        <p className="row mb-3 ">
                            <span className="col-md-4" style={{  fontWeight: "bold" }}>NCA</span><p className="col-md-4">means National Crime Agency.</p>
                        </p>
                        <p className="row mb-3">
                            <span className="col-md-4" style={{ fontWeight: "bold" }}>Political Exposed Persons </span> <p className="col-md-8">Political Exposed Persons	means Customers who are high-level public officials and their family members, and well-known personalities wishing to process payments using the Company’s platform.</p>  
                        </p>
                        <p className="row mb-3">
                            <span className="col-md-4" style={{ fontWeight: "bold" }}>SOSCPA</span>  <p className="col-md-8">means Serious Organised Crime and Police Act 2005.</p>
                        </p>
                        <p className="row mb-3">
                            <span className="col-md-4" style={{ fontWeight: "bold" }}>UNSC  </span>  <p className="col-md-8">means United Nations Security Council.</p> 
                        </p>
                     </div>
                     </p>
                    <br/>

                     <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                      3.	Scope of the Policy
                    </h2>
                  <p className="paragraph">
                  The broad definition of money laundering means that potentially anyone
                   could commit a money laundering offence, and this includes all employees of 
                   the Company, all temporary staff and contractors. This Policy is to enable the
                    Company to meet its legal and regulatory requirements in a way which is 
                    proportionate to the risk nature of the business, by taking reasonable steps
                     to minimise the likelihood of money laundering occurring.
                  </p>
                  <p className="paragraph">
                  This Policy applies to everyone involved in the company.
                   i.e. officers, employees, volunteers, suppliers, agency workers, 
                   contractors and shareholders. This Policy is made available internally
                    throughout the Company and management is required to ensure that everyone 
                    is aware of it and receives appropriate training.
                  </p>
                  <p className="paragraph">
                  All employees must be familiar with their legal
                   responsibilities and failure to comply with this Policy may lead
                    to disciplinary action.
                  </p>
                  <br/>

                  
                  <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                  4.	What is Money Laundering
                    </h2>
                  <p className="paragraph">
                  The principal money laundering legislation is the Proceeds of
                   Crime Act 2002 (POCA) as amended by the Serious Organised Crime 
                   and Police Act 2005 (SOCPA), supplemented by the Terrorism Act 2000
                    and the Fraud Act 2006. The secondary legislation is the Money Laundering, 
                    Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 
                    2017 (MLR 2017) as amended by the Money Laundering and Terrorist Financing
                     (Amendment) Regulations 2019 and Bribery Act 2010.
                  </p>
                  <p className="paragraph">
                  Money laundering can be defined as the process of moving illegally
                   acquired cash through financial systems so that it appears to be from
                    a legitimate source. Money laundering offences include: concealing, disguising,
                     converting, transferring criminal property or removing it from the UK; entering 
                     into or becoming concerned in an arrangement which you know or suspect 
                     facilitates the acquisition, retention, use or control of criminal property
                      by or on behalf of another person; and acquiring, using or possessing criminal 
                      property. 
                  </p>
                  <p className="paragraph">
                  There are also several secondary offences which include, 
                  failure to disclose knowledge or suspicion of money laundering 
                  to the Money Laundering Reporting Officer (MLRO); failure by the
                   MLRO to disclose knowledge or suspicion of money laundering to the
                    National Crime Agency (NCA); and ‘tipping off’ whereby somebody informs
                     a person or persons who are, or who are suspected of being involved in money
                      laundering, in such a way as to reduce the likelihood of their being 
                      investigated or prejudicing an investigation.
                  </p>
                  <p className="paragraph">
                  Any member of staff could potentially be in violation of the money
                   laundering provisions if they suspect money laundering and either become
                    involved with it in some way and/or do nothing about it. This Policy sets 
                    out how any concerns should be raised.
                  </p>
                  <br/>

                  <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                  5.	Money Laundering Reporting Officer (MLRO)
                    </h2>
                  <p className="paragraph">
                  The Company will appoint an MLRO to receive disclosures about money
                   laundering activity and be responsible for anti-money laundering activities
                    within the Company. The officer nominated to do this is Bridget Yadua-Soremekun.
                  </p>
                  <p className="paragraph">
                  The Company will also appoint a deputy MLRO who will 
                  be responsible in the absence of the nominated officer. 
                  The deputy MLRO is Emmanuel Afolabi
                  </p>
                  <p className="paragraph">
                  The MLRO will ensure that appropriate periodic training and awareness
                   are provided to new and existing employees, officers, contractors, shareholders
                    and volunteers of the Company.
                  </p>
                  <p className="paragraph">
                  The MLRO will ensure that appropriate anti-money laundering 
                  systems and processes are incorporated by the Company.
                  </p>
                  <br/>

                  <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                  6.	Customer Acceptance Policy
                    </h2>
                  
                    <p className="paragraph">
                    6.1	<span style={{fontWeight:"bold"}}>Business risk evaluation and management.</span>
                    </p>
                    <p className="paragraph">
                    The Company considers that the potential threat of becoming involved
                     in any money laundering or terrorism activity is directly related to the
                      type of business carried out by the Company and that such threat can
                       be more effectively and efficiently managed if the potential risk linked
                        to the business and products of the Company is known before.  
                    </p>
                    <p className="paragraph">
                    Classifying its products by risk levels shall enable the Company
                     to design and implement measures and controls to mitigate such risk. 
                     Likewise, it shall enable the Company to focus on those business lines 
                     and products that present greater risk. Therefore, the Company shall apply a 
                     procedure that shall enable them to determine the risk of the business lines 
                     in which they participate and the products they distribute, with respect to money
                      laundering or terrorism financing. The criteria and factors to be used for 
                      measuring such potential risk should also be identified. 
                    </p>
                    <p className="paragraph">
                    In the same sense, risks inherent in money laundering or
                     terrorism financing can be managed more effectively and efficiently
                      if the potential risk linked to the different types of customers and
                       their transactions is known beforehand. 
                    </p>
                   <p className="paragraph">
                   Having customers and their transactions identified by risk level shall
                    enable the Company to design and implement measures and controls to mitigate 
                    such risk. Likewise, it shall enable them to focus on those customers and 
                    transactions that present the greatest risk. In peculiar cases, such risk
                     may only become apparent over time, such concerns thus make it prudent to
                      monitor and/or report such customer's policies and activities as a fundamental 
                      component of our risk-based approach. 
                   </p>
                   <p className="paragraph">
                   In this sense, the Company shall design a procedure, based on the risk
                    consideration of their own business and the products marketed by them, which
                     shall provide an appropriate framework for segmenting their own customers by levels of money
                      laundering or terrorism financing risk. The criteria and factors to be used for making such
                       segmentation should also be identified.
                   </p>

                   <p className="paragraph">
                    6.2	<span style={{fontWeight:"bold"}}>Assessing risks and applying a risk-based approach.</span>
                    </p>
                    <p className="paragraph">
                    In compliance with the international standards on countering money laundering
                     and the financing of terrorism & proliferation based on FATF Recommendation 1, 
                     the Company shall apply a risk-based approach (RBA) to ensure that measures
                      to prevent or mitigate money laundering and terrorist financing are 
                      commensurate with the risks identified. 
                    </p>
                    <p className="paragraph">
                    Financial Action Task Force (FATF) is an independent inter-governmental
                     body that develops and promotes policies to protect the global financial
                      system against money laundering, terrorist financing and the financing of
                       proliferation of weapons of mass destruction.  
                    </p>
                    <p className="paragraph">
                    The Company shall adopt a risk-based approach in respect of profiling 
                    AML/CFT along the following lines:
                    <div className="">
                      <p>a.	Client; </p>
                       <p>b. Geographies; </p>
                        <p>c.	Product & services; and </p> 
                        <p>d.	Distribution channels. </p>
                    </div>
                    </p>
                    <p className="paragraph">
                    6.3	<span style={{fontWeight:"bold"}}>Prohibited customers or with reinforced acceptance measures.</span>
                    </p>
                    <p className="paragraph">
                    For money-laundering and terrorism financing risk control purposes,
                     the Company shall not accept the following categories of customers:
                     <div className="">
                      <p>a.	Persons included in any of the official lists of sanctions
                         ("applicable lists"). e.g. United Nations Security Council (UNSC) Sanctions List.  </p>
                       <p>b.	Persons about whom information is available indicating possible
                         involvement in criminal activities. </p>
                        <p>c.	Persons with businesses that make it impossible to verify
                           the legitimacy of their activities or the source of funds.  </p> 
                        <p>d.	Persons who refuse to provide the required information or documentation. </p>
                        <p>e.	Legal entities whose shareholder or control structure cannot be determined. </p>
                        <p>
                        f.	Casinos or gambling/betting establishments that are not officially authorized. 
                        </p>
                        <p>
                        g.	Financial institutions resident in countries or territories
                         without being physically present (also referred to as "shell companies")
                          and which do not belong to a regulated financial group. 
                        </p>
                    </div>
                    </p>
                    <p className="paragraph">
                    6.4	<span style={{fontWeight:"bold"}}>	Politically Exposed Persons.</span>
                    </p>
                    <p className="paragraph">
                    Customers classified as Political Exposed Persons (PEPs) shall only
                     be accepted as customers of the Company with prior authorization from
                      any member of senior management in line with section 35 of the MLR 2017. 
                    </p>
                    <p className="paragraph">
                    PEPs shall be subject to the Company’s risk evaluation procedure
                     and will be assigned risk ratings based on factors such as their
                      industry, transaction volume, geographic location, and PEP status. 
                      PEPs shall also be subject to the Company’s Enhanced Due Diligence (EDD) process.
                    </p>
                    <p className="paragraph">
                    6.5	<span style={{fontWeight:"bold"}}>Know-Your-Customer (KYC).</span>
                    </p>
                    <p className="paragraph">
                    The most effective means of preventing the use of the financial
                     system for money laundering or terrorism financing is to identify 
                     and know your customers, regardless of whether they are established 
                     customers or otherwise. 
                    </p>
                    <p className="paragraph">
                    Along these lines, the Company shall establish regulations,
                     procedures, and internal controls aimed at obtaining effective 
                     and complete knowledge of their customers and their activities, in order to:
                     <div className="">
                      <p>
                      a.	Confirm and document the true identity of customers who maintain process payments using its platform. 
                      </p>
                      <p>
                      b.	Confirm and document any additional customer information 
                      commensurate with the assessment of the money laundering and terrorism
                       financing risk. 
                      </p>
                      <p>
                      c.	Ensure that the Company does not engage in business
                       with any individuals or entities whose identities cannot be 
                       confirmed, who do not provide all required information, or who 
                       have provided information that is false or that contains significant 
                       inconsistencies that cannot be clarified. 
                      </p>
                      </div> 
                    </p>
                    <p className="paragraph">
                    For customer identification, the Company shall consider the following criteria: 
                      <div className="">
                     <p>
                     a.	In the case of individuals, an official identification document shall be
                      required to confirm the individual's identity. 
                     </p>
                     <p>
                     b.	For corporations and other legal entities, the documents of
                      incorporation must be presented, including information concerning the
                       customer's name, legal form, address, directors, and the corporate bylaws,
                        powers of attorney, entry in the appropriate register or other reliable 
                        identifying information. 
                     </p>
                     <p>
                     c.	Neither anonymous accounts nor accounts using fictitious names may be opened or maintained. 
                     </p>
                      </div>
                    </p>
                    <p className="paragraph">
                    In these cases, all requirements must be fulfilled, 
                    including identification of the beneficial owner of the account, 
                    in accordance with the provisions of established AML/CFT regulations.  
                    </p>
                    <p className="paragraph">
                    The Company shall have procedures for determining that person's identity
                     and relationship to the customer. All necessary measures shall be taken to
                      obtain information about the true identity of the person on whose behalf a 
                      relationship is established, or a significant transaction conducted
                       (that is, the beneficial owners) whenever the customer is acting on 
                       behalf of third parties or in cases where doubts exist as to whether
                        the customer is acting on its own behalf
                    </p>
                   <br/>

                   
                  <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                  7.	Suspicions of Money Laundering
                    </h2>
                    <p className="paragraph">
                    All employees must as soon as practicable report any knowledge
                     of or suspicion of (or where there are reasonable grounds to suspect)
                      suspicious activity to the MLRO in the prescribed form as set out in 
                      this Policy.
                    </p>
                    <p className="paragraph">
                    Once the matter has been reported to the MLRO, 
                    the employee must follow the directions given to him/her and must NOT
                     make any further enquiry into the matter.
                    </p>
                    <p className="paragraph">
                    The employee must NOT voice any suspicions to the person(s) whom
                     they suspect of money laundering, as this may result in the commission
                      of the offence of “tipping off”. They must NOT discuss the matter with 
                      others or note on the file that a report has been made to the MLRO in case this
                       results in the suspect becoming aware of the situation.
                    </p>
                    <br/>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    8.	Consideration of the Disclosure by the MLRO
                    </h2>
                    <p className="paragraph">
                    Once the MLRO has received the report, it must be evaluated in
                     a timely manner in order to determine whether:
                     <div className="">
                        <p>i. there is actual or suspected money laundering taking place; or</p>
                        <p>ii. there are reasonable grounds to know or suspect that this is the case; and</p>
                        <p>iii. whether the MLRO needs to lodge a Suspicious Activity Report (SAR) with the NCA via the SAR Online System.</p>
                     </div>
                      </p>
                      <p className="paragraph">
                      Where the MLRO concludes that there are no reasonable
                       grounds to suspect money laundering then consent will be given
                        for any on-going or imminent transaction(s) to proceed.
                      </p>
                      <p className="paragraph">
                      Where consent is required from the NCA for a transaction to
                       proceed, then the transaction(s) in question must not be undertaken 
                       or completed until the NCA has given specific consent, or there is
                        deemed consent through the expiration of the relevant time limits without
                         objection from the NCA.
                      </p>
                      <p className="paragraph">
                      All disclosure reports referred to the MLRO and reports
                       made to the NCA will be retained by the MLRO in a confidential
                        file kept for that purpose, for a minimum of 5 years.
                      </p>
                      <p className="paragraph">
                      The MLRO must also consider whether additional notifications
                       and reports to other relevant enforcement agencies should be made.
                      </p>
                      <br/>
                      <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    9. Customer Identification and Due Diligence
                    </h2>
                    <p className="paragraph">
                    Due diligence is performed on all customers who must provide basic 
                    information including full name, residential/business address, 
                    date of birth for individuals and registration details for corporate bodies. 
                    </p>
                    <p className="paragraph"><u>Enhanced Due Diligence</u></p>
                    <p className="paragraph">
                    It may be necessary for the Company to carry out enhanced due
                     diligence on certain customers where the customer or a transaction 
                     involving the customer appears to be “high risk”. This means that there
                      is a higher level of identification and verification of the customer’s identity
                       required. The following non-exhaustive list of situations may indicate
                        a “high risk”:
                        <div className="">
                         <p>•	a new customer;</p>
                         <p>•	a customer not well known to the Company;</p>
                         <p>•	customers in known high risk industries and/or jurisdictions;</p>
                         <p>•	transactions that are unusual or appear to be unusual for that customer;</p>
                         <p>•	highly complex transaction or payment arrangements;</p>
                         <p>•	the transaction involves a politically exposed person (“PEP”) 
                          or an immediate family member or a close associate of a PEP; and</p>
                          <p>•	no face-to-face meetings take place with the customer where this is usually expected. </p>
                        </div>
                    </p>
                    <p className="paragraph">
                    Employees must assess the money laundering risk for each 
                    customer and if they suspect enhanced due diligence is required,
                     you should speak to the MLRO before continuing any engagement with
                      the customer. The MLRO will be required to approve the continuance of
                       the business relationship. 
                    </p>
                    <p className="paragraph">
                    If enhanced due diligence is carried out, the MLRO must:
                    <div>
                      <p>•	obtain additional information on the customer and on the customer’s beneficial owner(s);</p>
                      <p>•	obtain additional information on the intended nature of the business relationship;</p>
                      <p>•	obtain information on the source of funds and source of wealth of the customer and customer’s beneficial owner(s); and</p>
                      <p>•	conduct enhanced monitoring of the business relationship.</p>
                    </div>
                    </p>
                    <p className="paragraph">
                    This may include but is not limited to the following:
                    <div>
                      <p>•	checking the organisation website to confirm the
                         identity of personnel, its business address and any other details;</p>
                        <p>
                        •	attending the customer at their business address;
                        </p>
                      <p>
                      •	obtaining additional information or evidence to establish
                       the identity of the customer and its beneficial owner(s), including
                        checking publicly available beneficial ownership registers of legal
                         entities such as the registers available at Companies House;
                      </p>
                      <p>
                      •	in the case of a PEP, seek the approval of senior management
                       and establish the source of wealth and source of funds; and 
                      </p>
                      <p>
                      •	ensure that the first payment is made into a bank account in the customer’s name. 
                      </p>
                    </div>
                    </p>

                    <p className="paragraph">
                    If satisfactory evidence of identity is not obtained at the outset,
                     then the business relationship or one-off transaction(s) cannot proceed
                      any further. A report should be filed with the MLRO who will then consider 
                      if a report needs to be submitted to the NCA.
                    </p>
                    <br/>

                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                     10.	Sanction Screening
                    </h2>
                    <p className="paragraph">
                    The Company shall perform comprehensive sanctions screenings on all
                     existing and potential customers, partners, beneficiaries, and transactions.
                      This includes but is not limited to individuals, entities, and entities 
                      related to such individuals. The MLRO shall oversee and execute sanction
                       screening processes on all existing and prospective customers, partners,
                        beneficiaries, and transactions.
                    </p>
                    <p className="paragraph">
                    The screening process shall encompass a thorough check
                     against global sanctions lists, which shall include lists maintained by:
                     <div>
                      <p>•	The United Nation’s Security Council</p>
                      <p>•	The Office of Foreign Assets Control</p>
                      <p>•	The European Union External Action Service</p>
                      <p>•	His Majesty’s Treasury</p>
                      <p>•	The Financial Action Task Force (FATF)</p>
                     </div>
                    </p>
                    <p className="paragraph">
                    The primary objective of the screening shall be to identify and mitigate
                     potential financial risks associated with engaging with customers, beneficiaries or
                      partners (whether individuals or entities) or certain transactions that may be
                       subject to sanctions or restrictions, and the screening process shall be ongoing, with regular updates. 
                    </p>
                    <p className="paragraph">
                    The MLRO shall file a Suspicious Activity Report (SAR) of all
                     matches with individuals, entities, or transactions found on
                      sanctions lists to the NCA.
                    </p>
                    <br/>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    11.	Ongoing Monitoring
                    </h2>
                    <p className="paragraph">
                    Employees should review customers at regular intervals to ensure
                     that the risk level of each customer's information and information
                      held on each customer is not only accurate and up to date but is 
                      consistent with the knowledge of the customer and its business.
                    </p>
                    <p className="paragraph">
                    Further due diligence may be required if new people become
                     customers of the Company. Any suspicious activity must be 
                     reported to the MLRO.
                    </p>
                    <br/>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    	12.	Data Protection
                    </h2>
                    <p className="paragraph">
                    In preventing money laundering and terrorist financing,
                     the personal data of the Company’s customers must be collected 
                     and processed in accordance with the Data Protection Act 2018. 
                    </p>
                    <br/>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    13.	Record Keeping
                    </h2>
                    <p className="paragraph">
                    Customer identification evidence and details of any relevant
                     transaction(s) for that customer must be retained for at least 5 (five) 
                     years from the end of any business relationship with that customer.
                    </p>
                    <br/>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    14.	Staff
                    </h2>
                    <p className="paragraph">
                    In compliance with the provisions of the MLR 2017,
                     the Training unit shall facilitate training programs 
                     that shall make employees and agents to be fully aware of their
                      obligations e.g. identifying suspicious transactions and equip them 
                      with the relevant skill required for the effective discharge of their 
                      AML/CFT tasks. 
                    </p>
                    <p className="paragraph">
                    The Company shall adopt any of the following staff training methods; 
                    <div>
                      <p>a.	Classroom training;</p>
                      <p>b.	Electronic learning management systems (ELMS); </p>
                      <p>c.	Email Communication broadcast to all Staff on AML/CFT subject matter; or  </p>
                      <p>d.	Mandatory company-wide Knowledge sharing sessions </p>
                    </div>
                    </p>
                    <p className="paragraph">
                    The training programs shall consider international standards
                     and local legislation to prevent money laundering and terrorism financing,
                      the latest trends in criminal activity, and the Company’s policies and procedures 
                      designed to counter money laundering and terrorism financing, including how to
                       recognize and report suspicious activities. 
                    </p>
                    <p className="paragraph">
                    A specific record shall be kept of all training
                     activity given, stating the date, place and duration of each
                      course, the number of attendees and the Unit to which they belong 
                    </p>
                    <br/>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    15.	Implementation of Policy
                    </h2>
                    <p className="paragraph">
                    This Policy shall be deemed effective as of August 1, 2023.
                     No part of this Policy shall have retroactive effect and shall thus apply
                      only to matters occurring on or after this date. 
                    </p>
                    <br/>
                    <p className="paragraph">
                    This Policy has been approved and authorised by: 
                    </p>
                    <p className="paragraph">
                    Name : <span style={{fontWeight:"bold"}}>Bankole Alao</span>
                    </p>
                    <p className="paragraph">
                    Position  : <span style={{fontWeight:"bold"}}>CEO</span>
                    </p>
                    <p className="paragraph">
                    Date  : <span style={{fontWeight:"bold"}}> August 1, 2023</span>
                    </p>
                    <br/>
                    <br/>

                    

                  



                   
                    
                    

                  
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction/>
      <Footer />
    </div>
  );
};

export default AmlPolicy;
// export default withWelcomeLayout(PrivacyPage, config);
