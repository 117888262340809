import React from "react";
import ModalComponent from "../ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { cancelOrder } from "store/actions/orders";
import InputField from "components/blocks/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { orderCancelationReason } from "libs/constants";

const CancelOrderModal = ({ order, setCancelModal, cancelModal }) => {
  const { updatingOrderStatus, success } = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const cancelSchema = yup.object().shape({
    reason: yup.string().required("required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(cancelSchema),
  });

  const onCancelOrder = (values) => {
    const data = { orderID: String(order?.orderId), reason: values.reason };
    dispatch(cancelOrder(data));
  };

  useEffect(() => {
    if (success) {
      setCancelModal(false);
    }
  }, [success]);
  return (
    <ModalComponent show={cancelModal} centered size="md">
      <div className="modal__component">
        <div className="modal__title">Cancel order?</div>
        <div className="modal__content">
          <form onSubmit={handleSubmit(onCancelOrder)} className="w-100">
            <p className="text-center">
              It's sad you had to cancel your order, please we would love to
              know why{" "}
            </p>
            <div className="form__wrapper">
              <InputField
                require={true}
                id="reason"
                label="Cancelation Reason"
                name="reason"
                placeHolder="Cancelation Reason"
                register={register}
                options={orderCancelationReason}
                error={errors.reason?.message}
                type="select"
              />
            </div>
            <div className="modal__control">
              <button
                className="button button--purple-outline px-4"
                onClick={() => setCancelModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button button--purple px-4"
                disabled={updatingOrderStatus}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </ModalComponent>
  );
};

export default CancelOrderModal;
