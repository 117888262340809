import React, { Fragment, useState } from "react";
import { withAuthLayout } from "components/layouts";
import { Input, Button, AuthFooter } from "components/blocks";
import axios from "axios";
import { PepperestWebServices } from "libs/constants";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const option = {
      "Content-Type": "application/json",
      email,
    };
    axios
      .post(`${PepperestWebServices.HOST}/EscrowBackend/api/password/resetByEmail`, option)
      .then((response) => {
        setMessage(response.data.Detail);
        setLoading(false);
        setEmail("");
      })
      .catch((error) => {
        setError(error.response.data.Detail);
        setLoading(false);
      });

    setTimeout(() => {
      setError("");
    }, 6000);
  };

  return (
    <Fragment>
      <div className="auth-panel auth-panel--alternate">
        <div className="auth-panel__head">
          <h4 className="auth-panel__head-header">Forgot Password?</h4>
          <p className="auth-panel__head-help">
            Request for a password reset. An email will be sent to you.
          </p>
        </div>
        <div className="auth-panel-main">
          <div className="text-center text-success">{message && message}</div>
          <div className="text-center text-danger">{error && error}</div>
          <form className="nsForm auth-panel-form" method="POST">
            <Input
              name="email"
              type="email"
              placeholder="Enter Email Address"
              label="Email Address"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Button
              type="submit"
              value={
                loading == true ? (
                  <div>
                    <div className="spinner-border text-white" role="status"></div>
                  </div>
                ) : (
                  "SEND RECOVERY EMAIL"
                )
              }
              name="send_recovery"
              handleClick={(e) => handleSubmit(e)}
            />
          </form>
        </div>
      </div>
      <AuthFooter
        value="SIGN IN"
        url="/"
        label="Already have an account ?"
        isAlternate={false}
      />
    </Fragment>
  );
};

export default withAuthLayout(ForgotPassword, {});
