import { HamburgerIcon } from "components/vectors";
import ArrowDown from "components/vectors/ArrowDown";
import CopyIcon from "components/vectors/CopyIcon";
import DollarCircle from "components/vectors/DollarCircle";
import LogoutIcon from "components/vectors/LogoutIcon";
import User from "components/vectors/User";
import UserIcon from "components/vectors/UserIcon";
import ViewIcon from "components/vectors/ViewIcon";
import { useStateContext } from "context/activeStateContext";
import { mixPanelUserProperty } from "libs/mixPanel";
import { handleCopy } from "libs/utils";
import { trackEvent } from "libs/utils/customer-io";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function TopNav() {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const { userProfile } = useSelector((state) => state.userAccount);
  const dropdownRef = useRef(null);

  const toggleShow = () => {
    setShowDropdown(false);
  };
  const handleLogout = () => {
    mixPanelUserProperty("logged_out", {});
    setShowDropdown(false);
  };

  
  const copyStoreLink = () => {
    const url = `https://peppa.io/store/${userProfile?.merchantCode}`;
    handleCopy(url, "Store link copied");
    trackEvent("copy_store_link");
    setShowDropdown(false);
  };
  const dropDownMenu = [
    {
      title: "Profile",
      link: "/dashboard/profile",
      icon: <UserIcon color="#18191F" width={14} height={14} />,
      onClick: () => setShowDropdown(false),
    },
    {
      title: "Copy store link",
      link: "#",
      icon: <CopyIcon />,
      onClick: copyStoreLink,
    },
    {
      title: "Request loan",
      link: "#",
      icon: <DollarCircle />,
      onClick: () => setShowDropdown(false),
    },
    {
      title: "Billing Info",
      link: "/dashboard/plan",
      icon: <DollarCircle />,
      onClick: () => setShowDropdown(false),
    },
    {
      title: "Log out",
      link: "/logout",
      icon: <LogoutIcon />,
      onClick: () => handleLogout,
    },
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="top__nav">
      <div
        onClick={() => setActiveMenu((prev) => !prev)}
        className="hamburger__menu"
      >
        <HamburgerIcon transparent={false} />
      </div>
      <section className="nav__menu__wrapper">
        <a
          href={`https://peppa.io/store/${userProfile?.merchantCode}`}
          target="_blank"
          className="menu__item"
        >
          <p className="menu__link">View store </p>
          <ViewIcon />
        </a>
        <section className="" ref={dropdownRef}>
          <div className="user__container">
            <div className="user__icon">
              {userProfile?.store_logo ? (
                <img src={userProfile?.store_logo} className="store__logo" />
              ) : (
                <>
                  <span className="logo__default">
                    <User />
                  </span>
                </>
              )}
            </div>

            <div className="user__info">
              <p className="merchant__name">{userProfile?.businessname}</p>
              <p className="merchant__username">{userProfile?.merchantCode}</p>
            </div>
            <div
              className="dropdown__arrow"
              tabIndex={0}
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              <ArrowDown />
            </div>
          </div>

          <div
            className={`dropdown__content ${
              showDropdown ? "dropdown__show" : ""
            }`}
            onBlur={() => setShowDropdown(false)}
          >
            {dropDownMenu.map((item, index) => (
              <li className="dropdown__item" key={index}>
                {item.icon}
                <Link to={item.link} onClick={item.onClick}>
                  {item.title}
                </Link>
              </li>
            ))}
          </div>
        </section>
      </section>
    </div>
  );
}

export default TopNav;
