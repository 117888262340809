export default {
  ORDERS: "/EscrowBackend/api/myOrders",
  MY_PURCHASES: "/EscrowBackend/api/myPurchases",
  PLACE_ORDER: "/EscrowBackend/api/order/placeOrder",
  CONFIRM_ORDER: "EscrowBackend/api/order/payment/callback",
  VIEW_ORDER: "EscrowBackend/api/order/orderDetails",
  ADDRESS: "/EscrowBackend/api/order/buyerAddress",
  ADD_ADDRESS: "/EscrowBackend/api/order/buyerAddress/add",
  ADD_PICK_UP_ADDRESS: "/EscrowBackend/api/userAccount/addPickupAddress",
  GET_PICK_UP_ADDRESS: "/EscrowBackend/api/userAccount/getPickupAddress",
  UPDATE_PICK_UP_ADDRESS: "/EscrowBackend/api/userAccount/modifyPickupAddress",
  UPDATE_ADDRESS: "/EscrowBackend/api/order/buyerAddress/update",
  REMOVE_ADDRESS: "/EscrowBackend/api/order/buyerAddress/remove",
  CHANGE_ORDER_STATUS: "/EscrowBackend/api/order/changeOrderStatus",
  GET_REFFERALS_STATS: "/EscrowBackend/api/userAccount/getReferralStats",
  GET_COURIER_SERVICE: "/EscrowBackend/api/order/getShipmentRates",
  GET_THIRDPARTY_ORDER:
    "/EscrowBackend/api/ThirdParty/orderTranx/getOrderTranx",
  THIRD_PARTY_PAYMENT_LINK:
    "/EscrowBackend/api/ThirdParty/OrderPayment/getPaymentLink",
  RAISE_DISPUTE: "/EscrowBackend/api/order/raiseDispute",
  RAISE_WALLET_ISSUE: "/EscrowBackend/api/wallet/openDispute",
  CANCEL_ORDER: "/EscrowBackend/api/order/cancel",
};

export const PAGE_TYPES = {
  DASHBOARD: "Dashboard",
  ALL: "All",
  PENDING: "Pending",
  CANCELED: "Canceled",
  SHIPPED: "Shipped",
  DELIVERED: "Delivered",
  PAID: "Paid",
};

export const ErrorMessages = {
  getHistoryFailed: "We are currently unable to get your orders data",
  purchases: "We are currently unable to get your purcahses data",
};
