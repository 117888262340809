import { Loader } from "components/blocks";
import { CheckedIcon, CloseIcon } from "components/vectors";
import { premiumPackage } from "libs/constants";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPricingPlans, unsubScribePremium } from "store/actions/userAccount";
import ModalComponent from "../ModalComponent";
import { useHistory } from "react-router-dom";

const PlanCard = ({ plan, subscriptionType }) => {
  const [showModal, setShowModal] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const {
    unsubscribing,
    error,
    unsubscribeSuccess,
    userProfile,
    subscription,
    subscriptionError,
    loadingSubscriptions,
  } = useSelector((state) => state.userAccount);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const unsubscribe = () => {
    dispatch(unsubScribePremium());
  };

  useEffect(() => {
    if (!subscription) {
      dispatch(getPricingPlans());
    }
  }, []);

  useEffect(() => {
    if (subscription) {
      const detail = subscription?.find(
        (sub) => sub?.id === subscriptionType?.subscription_id
      );
      setPlanInfo(detail);
    }
  }, [subscription, subscriptionType]);

  useEffect(() => {
    if (unsubscribeSuccess || !unsubscribing) {
      setShowModal(false);
    }
  }, [unsubscribeSuccess, unsubscribing]);

  console.log({ subscriptionType });

  const handleUpgradePlan = () => {
    history.push("/dashboard/upgrade-plan");
  };
  return (
    <div className="plan__card">
      <div className="card__header">
        <div className="plan__type__wrapper">
          <p className="plan__type">{plan} Plan</p>
          <p className="plan__status">Active</p>
        </div>
        <div className="plan__mode">
          {plan === "Premium" ? (
            <p className="plan__price">
              <span className="price">{planInfo?.price} </span>
              {planInfo?.description}
            </p>
          ) : (
            "FREE"
          )}
        </div>
      </div>
      <div className="card__content">
        <div className="card__item">
          <p className="card__label__label">Billing Interval</p>
          <p className="item__content mt-1">
            {planInfo ? planInfo?.type : "N/A"}
          </p>
        </div>
        <div className="card__item">
          <p className="card__label__label">Last Payment</p>
          <p className="item__content mt-1">
            {planInfo ? userProfile?.subStartDate : "N/A"}
          </p>
        </div>
        <div className="card__item">
          <p className="card__label__label">Next Payment</p>
          <p className="item__content mt-1">
            {planInfo ? userProfile?.subEndDate : "N/A"}
          </p>
        </div>
      </div>
      <hr className="content__divider" />
      <div className="card__footer">
        <p className="cursor__pointer" onClick={() => setShowFeatures(true)}>
          See features
        </p>
        {plan === "Premium" ? (
          <p className="cursor__pointer" onClick={toggleModal}>
            Cancel subscrption
          </p>
        ) : (
          <button
            className="button button--purple button__content"
            onClick={handleUpgradePlan}
          >
            Upgrade Plan
          </button>
        )}
      </div>

      <ModalComponent show={showModal} centered size="md">
        <div className="modal__component">
          <div className="modal__title">Cancel subscription?</div>
          <div className="modal__content">
            <p>Are you sure you want to Cancel your subscription?</p>
            <div className="modal__control">
              <button
                className="button button--purple-outline col-md-6"
                disabled={unsubscribing}
                onClick={unsubscribe}
              >
                {unsubscribing ? <Loader /> : "Yes, cancel"}
              </button>
              <button
                className="button button--purple col-md-6"
                onClick={toggleModal}
              >
                Don’t cancel
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent show={showFeatures} centered size="md">
        <div className="modal__component">
          <div className="modal__title">
            <div className="d-flex justify-content-between">
              <p>Premium Features</p>
              <p>
                <CloseIcon onClick={() => setShowFeatures(false)} />
              </p>
            </div>
          </div>
          <div className="modal__content">
            <div className="plan__content">
              {premiumPackage.map((item) => (
                <li className="plan__item">
                  <CheckedIcon color="#5E60CE" width="16" height="16" />
                  <p className="plan__text">{item}</p>
                </li>
              ))}
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default PlanCard;
