import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getPricingPlans, subscribePremium } from "store/actions/userAccount";
import { Loader } from "components/blocks";
import { plans } from "libs/constants";
import PlanListItem from "components/dashboardComponent/PlanListItem";
import { LoaderProcessing } from "components/shared";
import { trackEvent } from "libs/utils/customer-io";
import PayOptionModal from "components/dashboardComponent/payOptionModal";
import ModalComponent from "components/dashboardComponent/ModalComponent";
import { useHistory } from 'react-router-dom';

const UpgradePlan = () => {
  const history = useHistory()
  const url = window.location.origin;
  const { token, userInfo } = useSelector((state) => state.auth);
  const {
    tokenization,
    upgradingPremium,
    subscription,
    subscriptionError,
    loadingSubscriptions,
  } = useSelector((state) => state.userAccount);
  const dispatch = useDispatch();
  const [digitalCampaign, setDigitalCampaign] = useState(false);
  const [payOption, setPayOption] = useState(false);
  const [activePlan, setActivePlan] = useState(null);
  const [total, setTotal] = useState(2000);

  const handleClick = () => {
    setDigitalCampaign((prev) => !prev);
    setTotal((prev) => (prev > 2000 ? prev - 10000 : prev + 10000));
  };

  useEffect(() => {
    if (!subscription) {
      dispatch(getPricingPlans());
    }
  }, []);

  const tokenizeCard = (payWith=null) => {
    let param;
    if (payWith === "wallet"){
       param = {
        redirectUrl: `${url}/dashboard/verify-payment?subscriptionId=${activePlan?.id}`,
        subscription_id: activePlan?.id,
        payWith:"wallet"
      };
    }else{
       param = {
        redirectUrl: `${url}/dashboard/verify-payment?subscriptionId=${activePlan?.id}`,
        subscription_id: activePlan?.id,
      };
    }
    
    if (digitalCampaign) {
      param.additional_cost = 10000;
      trackEvent("include_digital_campaign_on_subscription");
    }

  dispatch(subscribePremium(param, token, userInfo));
  setPayOption(false)
  if (payWith === "wallet"){
    history.push("/dashboard/plan")
  }

  };

  useEffect(() => {
    if (subscription) {
      setActivePlan(subscription[0]);
    }
  }, [subscription]);

  useEffect(() => {
    if (tokenization?.paymentUrl && tokenization?.error == 0) {
      window.location.href = tokenization?.paymentUrl;
    }
  }, [tokenization?.paymentUrl]);

  return (
    <main className="upgrade__plan">
      {loadingSubscriptions ? (
        <div className="loader__container">
          <LoaderProcessing />
        </div>
      ) : (
        <section>
          <h3 className="title__text">Billing Plans</h3>
          <p className="plan__description">Select your plan</p>

          <div className="plan__container">
            <section className="plans">
              {subscription?.map((plan) => (
                <div
                  onClick={() => setActivePlan(plan)}
                  className={`${
                    activePlan?.type == plan?.type
                      ? "active__plan plan__type "
                      : "plan__type "
                  }}`}
                  key={plan.id}
                >
                  {plan?.type}
                </div>
              ))}
            </section>
          </div>

          <main className="pricing__container">
            <section className="pricing__box borderContainer">
              <div className="price__header">
                <h2 className="title">Basic Plan</h2>
                <p className="duration">
                  Free <span className="period">Forever</span>
                </p>
              </div>
              <h2 className="common">Included in all plans:</h2>
              <div className="free__plan">
                {freePackage.map((item, index) => (
                  <PlanListItem title={item} key={index} />
                ))}
              </div>
            </section>
            <section className="pricing__box">
              <div className="price__header">
                <h2 className="title">Premium Plan</h2>
                {subscriptionError ? (
                  <p>{subscriptionError}</p>
                ) : (
                  <>
                    <p className="duration">
                      ₦{activePlan?.price}{" "}
                      <span className="period">{activePlan?.description}</span>
                    </p>
                    {activePlan?.price_saving > 0 ? (
                      <div className="price__discount">
                        <p className="discount">₦{activePlan?.price_saving}</p>
                        <p className="discount_period">{`Billed ${activePlan?.type}`}</p>
                      </div>
                    ) : null}
                  </>
                )}
                <div className="digital__title__container">
                  <label>
                    <input
                      type="checkbox"
                      checked={digitalCampaign}
                      onChange={handleClick}
                      className="form_input"
                    />
                    <span></span>
                  </label>
                  <p className="title">
                    Also subscribe me to the Digital Campaign
                  </p>
                </div>
              </div>
              <h2 className="common">Everything in Basic:</h2>
              <div className="free__plan">
                {premiumPackage.map((item, index) => (
                  <PlanListItem title={item} key={index} />
                ))}
              </div>
              <button
                className="button button--purple mt-4"
                onClick={()=>setPayOption(true)}
               // onClick={tokenizeCard}
               // disabled={upgradingPremium || !activePlan}
              >
                Upgrade to Premium
              </button>
            </section>
          </main>
        </section>
      )}

      <ModalComponent show={payOption} centered size="md">
        <PayOptionModal
          close={() => setPayOption(false)}
          tokenizeCard={tokenizeCard}
          upgradingPremium={upgradingPremium}
        />
      </ModalComponent>
    </main>
  );
};

export default UpgradePlan;
const premiumPackage = [
  "Up to 100 products uploads",
  "Special Store features on the Peppa App and across social media",
  "Store customization/branding (like - store url customization, ability to change header image)",
  "Reduced transaction charges",
  "Invoice financing",
  "Weekly/monthly bulletin",
  "Visitors (to store) tracking stats",
  "Auto-synchronization with Iinstagram (two-ways)",
  "Ability to give discounts to customers",
  "Send customers SMS and emails from your store",
];
const flatPackage = [
  "Buyer protection",
  "Quick refunds processing",
  "24/7 customer support",
];
const freePackage = [
  "Online store",
  "Upload up to 20 products",
  "Upload store logo",
  "Free unique store link",
  "Door to Door delivery",
];
