export default {
  TRANSACTIONS: "/EscrowBackend/api/myPayments",
  INCOMING_PAYMENT: "/EscrowBackend/api/paysure/payment/getTransactionByRef",
  TRACK_INCOMING_PAYMENT:
    "/EscrowBackend/api/paysure/payment/getTransEventsByRef",
  CONFIRM_PREMIUM_SUBSCRIPTION: "/EscrowBackend/api/plan/upgrade",
  PAYMENTS: "/EscrowBackend/api/payments",
  STORE_PAYMENTS: "/EscrowBackend/api/orders",
  DECLINE_PAYMENT:"/EscrowBackend/api/paysure/payment/declineTranxByRef",
  PENDING_PAYMENT:"/EscrowBackend/api/paysure/payment/getTransactions"
};

export const STATUS = {
  ALL: "all",
  PENDING: "Pending",
  PAID: "Paid",
  REFUNDED: "Refunded",
  RELEASED: "Released",
  STOP: "Stop",
};

export const ErrorMessages = {
  getHistoryFailed: "We are currently unable to get your payment data",
};
