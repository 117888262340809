export default {
  PRODUCTS: "/EscrowBackend/api/myProducts",
  GET_SINGLE_PRODUCT: "/EscrowBackend/api/product/getProduct",
  GET_INFO: "/EscrowBackend/api/product/social/getInfo",
  GET_PAGE_PRODUCTS: "/EscrowBackend/api/product/social/pageProducts",
  PUBLISH_SINGLE_PRODUCT: "/EscrowBackend/api/product/add",
  PUBLISH_SINGLE_SOCIAL_PRODUCT: "/EscrowBackend/api/product/social/publishSingleProducts",
  PUBLISH_SOCIAL_PRODUCTS: "/EscrowBackend/api/product/social/publishProducts",
  UPDATE_PRODUCT: "/EscrowBackend/api/product/update",
  REMOVE_PRODUCT: "/EscrowBackend/api/product/remove",
  REMOVE_MULTIPLE_PRODUCT: "/EscrowBackend/api/product/remove/multiple",
  MERCHANT_STORE: "/EscrowBackend/api/product/merchantStore",
  CREATE_INVOICE: "/EscrowBackend/api/invoice/create",
  PRODUCT_CATEGORIES:"/EscrowBackend/api/product/categories",
  PRODUCT_WEIGHT_UNIT:"/EscrowBackend/api/product/weightUnits",
  ADD_DISCOUNT:"/EscrowBackend/api/discount/add",
  GET_DISCOUNTS:"/EscrowBackend/api/myDiscounts",
  UPDATE_DISCOUNT:"/EscrowBackend/api/discount/update",
  REMOVE_DISCOUNT:"/EscrowBackend/api/discount/remove",
  ALL_PRODUCTS: "/EscrowBackend/api/product/withoutDiscount",
  MY_COUPONS: "/EscrowBackend/api/myCoupons",
  REMOVE_COUPON: "/EscrowBackend/api/coupon/remove",
  UPDATE_COUPON: "/EscrowBackend/api/coupon/update",
  ADD_COUPON: "/EscrowBackend/api/coupon/add",
  TOGGLE_PRODUCT_STOCK: "/EscrowBackend/api/product/stock/toggle",
  
};

export const ErrorMessages = {
  getHistoryFailed: "We are currently unable to get your products data",
  getMerchantFailed: "Unable to fetch merchant products from store",
};
