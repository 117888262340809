import * as actionTypes from "../actions/actionTypes";
import { getStateFromLocalStorage, updateObject } from "../utility";

const initialState = {
  addresses: [],
  pickUpAddress: {},
  updatingOrderStatus: null,
  success: false,
  addressError: null,
  redirectUrl: null,
  paymentGateway: null,
  loading: true,
  updatingPickupAddress: false,
  confirmOrderDetails: null,
  referralStats: {},
  courierRates: {},
  thirdPartyOrder: {},
  cartOrder: JSON.parse(getStateFromLocalStorage("cartOrder")) || null,
  // thirdPartyLoading: false,
  orderDetails: {},
  allOrders: {
    orders: [],
    meta: null,
    links: null,
    loading: false,
    loaded: false,
    error: null,
  },
  myPurchases: {
    orders: [],
    meta: null,
    links: null,
    loading: false,
    loaded: false,
    error: null,
  },
  raisingDispute: false,
  disputeComplete: false,
  disputeError: null,

  raisingWalletIssue: false,
  walletIssueComplete: false,
  walletIssueError: null,
};

const loading = (state, action) => {
  const update = { loading: true };
  return updateObject(state, update);
};

const updatingOrderStatus = (state, action) => {
  const update = { updatingOrderStatus: true, success: false };
  return updateObject(state, update);
};

const updatingOrderStatusSuccess = (state, action) => {
  const update = {
    success: true,
    updatingOrderStatus: false,
  };
  return updateObject(state, update);
};

const updatingOrderStatusFail = (state, action) => {
  const update = { success: false, updatingOrderStatus: false };
  return updateObject(state, update);
};
// const thirdPartyloading = (state, action) => {
//   const update = { thirdPartyloading: action.loading };
//   return updateObject(state, update);
// };

const setAddress = (state, action) => {
  const update = {
    addresses: action.addresses,
  };
  return updateObject(state, update);
};

const setPickupAddress = (state, action) => {
  const update = {
    pickUpAddress: action.address,
    updatingPickupAddress: false,
  };
  return updateObject(state, update);
};

const updatePickupAddress = (state, action) => {
  return updateObject(state, {
    updatingPickupAddress: true,
  });
};

const updatePickupAddressFail = (state, action) => {
  return updateObject(state, {
    updatingPickupAddress: false,
  });
};

const setCourierRates = (state, action) => {
  const update = { courierRates: action.rates };
  return updateObject(state, update);
};

const addressError = (state, action) => {
  return updateObject(state, { addressError: true });
};

const changeOrderStatus = (state, action) => {
  const update = {};
  const newOrder = state.allOrders.orders.filter((order) => {
    return order.status === action.order?.status;
  });
  update[action.order?.status] = newOrder;
  return updateObject(state, update);
};

const changeOrderStatusError = (state, action) => {
  const update = {};
  update[action.pageType] = action.pageTypeUpdate;
  return updateObject(state, update);
};

const loadedOrder = (state, action) => {
  const update = {
    allOrders: action.pageTypeUpdate,
    loading: false,
    error: null,
  };
  return updateObject(state, update);
};

const loadedPurchases = (state, action) => {
  const update = {
    myPurchases: action.pageTypeUpdate,
    loading: false,
    error: null,
  };
  return updateObject(state, update);
};

const failedToLoadOrder = (state, action) => {
  const update = {
    loading: false,
    loaded: false,
    error: action.error,
  };
  return updateObject(state, update);
};

const failedToLoadPurchases = (state, action) => {
  const update = {
    loading: false,
    loaded: false,
    error: action.error,
  };
  return updateObject(state, update);
};

const redirect = (state, action) => {
  const update = { redirectUrl: action.redirectUrl };
  return updateObject(state, update);
};

const orderpaymentGateway = (state, action) => {
  const update = { paymentGateway: action.paymentGateway };
  return updateObject(state, update);
};

const cartOrder = (state, action) => {
  const update = { cartOrder: action.cartOrder };
  return updateObject(state, update);
};

const confirmLoading = (state, action) => {
  return updateObject(state, { loading: action.loading });
};

const orderConfirmed = (state, action) => {
  return updateObject(state, action.payload);
};
const viewOrderDetails = (state, action) => {
  const update = { loading: true, error: "" };
  return updateObject(state, update);
};

const viewOrderDetailsSuccess = (state, action) => {
  const update = {
    error: "",
    orderDetails: action.payload.orderDetails,
    loading: false,
  };
  return updateObject(state, update);
};
const viewOrderDetailsFail = (state, action) => {
  const update = { error: action.error, loading: false };
  return updateObject(state, update);
};

const setReferralStats = (state, action) => {
  const update = { referralStats: action.stats };
  return updateObject(state, update);
};
const thirdPartyOrder = (state, action) => {
  const update = { thirdPartyOrder: action.payload };
  return updateObject(state, update);
};

const raiseDispute = (state, action) => {
  const update = { raisingDispute: true, disputeError: "" };
  return updateObject(state, update);
};

const raiseDisputeSuccess = (state, action) => {
  const update = {
    disputeComplete: true,
    raisingDispute: false,
    disputeError: "",
  };
  return updateObject(state, update);
};

const raiseDisputeFail = (state, action) => {
  const update = {
    disputeError: action.error,
    disputeComplete: false,
    raisingDispute: false,
  };
  return updateObject(state, update);
};
const raiseDisputeComplete = (state, action) => {
  const update = {
    disputeComplete: false,
    raisingDispute: false,
    disputeError: null,
  };
  return updateObject(state, update);
};



const raiseWalletIssue = (state, action) => { 
  const update = { raisingWalletIssue: true, walletIssueError: "" };
  return updateObject(state, update);
};

const raiseWalletIssueSuccess = (state, action) => {
  const update = {
    walletIssueComplete: true,
    raisingWalletIssue: false,
    walletIssueError: "",
  };
  return updateObject(state, update);
};

const raiseWalletIssueFail = (state, action) => {
  const update = {
    walletIssueError: action.error,
    walletIssueComplete: false,
    raisingWalletIssue: false,
  };
  return updateObject(state, update);
};

const raiseWalletIssueComplete = (state, action) => {
  const update = {
    walletIssueComplete: false,
    raisingWalletIssue: false,
    walletIssueError: null,
  };
  return updateObject(state, update);
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_ORDERS:
      return loading(state, action);
    case actionTypes.FINISHED_LOADING_ORDERS:
      return loadedOrder(state, action);
    case actionTypes.LOADING_ORDERS_FAILED:
      return failedToLoadOrder(state, action);
    case actionTypes.LOADING_MY_PURCHASES:
      return loading(state, action);
    case actionTypes.FINISHED_LOADING__MY_PURCHASES:
      return loadedPurchases(state, action);
    case actionTypes.LOADING_MY_PURCHASES_FAILED:
      return failedToLoadPurchases(state, action);
    case actionTypes.LOAD_ADDRESS:
      return setAddress(state, action);
    case actionTypes.ADD_PICK_UP_ADDRESS:
      return setPickupAddress(state, action);
    case actionTypes.ADD_PICKUP_ADDRESS_START:
      return updatePickupAddress(state, action);
    case actionTypes.ADD_PICKUP_ADDRESS_FAIL:
      return updatePickupAddressFail(state, action);
    case actionTypes.LOAD_ADDRESS_ERROR:
      return addressError(state, action);
    case actionTypes.CHANGE_ORDER_STATUS:
      return updatingOrderStatus(state, action);
    case actionTypes.CHANGE_ORDER_STATUS_SUCCESS:
      return updatingOrderStatusSuccess(state, action);
    case actionTypes.CHANGE_ORDER_STATUS_FAILED:
      return updatingOrderStatusFail(state, action);
    case actionTypes.ORDER_REDIRECT:
      return redirect(state, action);
    case actionTypes.ORDER_PAYMENT_PROVIDER:
      return orderpaymentGateway(state, action);
    case actionTypes.CART_ORDER:
      return cartOrder(state, action);
    case actionTypes.CONFIRM_LOADING:
      return confirmLoading(state, action);
    case actionTypes.CONFIRM_ORDER_DETAILS:
      return orderConfirmed(state, action);
    case actionTypes.VIEW_ORDER_DETAILS:
      return viewOrderDetails(state, action);
    case actionTypes.VIEW_ORDER_DETAILS_SUCCESS:
      return viewOrderDetailsSuccess(state, action);
    case actionTypes.VIEW_ORDER_DETAILS_FAIL:
      return viewOrderDetailsFail(state, action);
    case actionTypes.CONFIRM_THIRDPARTY_ORDER_DETAILS:
      return thirdPartyOrder(state, action);
    case actionTypes.SET_REFERRAL_STATS:
      return setReferralStats(state, action);
    case actionTypes.SET_COURIER_SERVICE:
      return setCourierRates(state, action);
    case actionTypes.RAISE_DISPUTE:
      return raiseDispute(state, action);
    case actionTypes.RAISE_DISPUTE_SUCCESS:
      return raiseDisputeSuccess(state, action);
    case actionTypes.RAISE_DISPUTE_FAIL:
      return raiseDisputeFail(state, action);
    case actionTypes.RAISE_DISPUTE_COMPLETE:
      return raiseDisputeComplete(state, action);
    case actionTypes.RAISE_WALLET_ISSUE:
      return raiseWalletIssue(state, action);
    case actionTypes.RAISE_WALLET_ISSUE_SUCCESS:
      return raiseWalletIssueSuccess(state, action);
    case actionTypes.RAISE_WALLET_ISSUE_FAIL:
      return raiseWalletIssueFail(state, action);
    case actionTypes.RAISE_WALLET_ISSUE_COMPLETE:
      return raiseWalletIssueComplete(state, action);
    case actionTypes.CANCEL_ORDER:
      return updatingOrderStatus(state, action);
    case actionTypes.CANCEL_ORDER_SUCCESS:
      return updatingOrderStatusSuccess(state, action);
    case actionTypes.CANCEL_ORDER_FAIL:
      return updatingOrderStatusFail(state, action);
    default:
      return state;
  }
};

export default reducer;
