import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  UserAccount,
  UserAccountErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { setAlert } from "./alert";
import { getStringHash, notify } from "libs/utils";
import { logout } from ".";
import { mixPanelUserProperty } from "libs/mixPanel";
import { toast } from "react-toastify";
import { trackEvent } from "libs/utils/customer-io";


export const getUserProfile = (token, user) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_USER_PROFILE });
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      customerID: user?.customerID,
    };
    PepperestAxios.get(UserAccount.PROFILEINFO, { params, headers })
      .then((response) => {
        dispatch(getUserProfileSuccess(response?.data?.userProfile));
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_USER_PROFILE_FAIL });
      });
  };
};

export const resendVerificationEmail = (token, user) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESEND_VERIFICATION_EMAIL });
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };

    PepperestAxios.get(UserAccount.RESEND_VERIFICATION_EMAIL, { headers })
      .then((response) => {
        const message = response.data.message;
        notify(message, "success");
        dispatch({ type: actionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS });
      })
      .catch((error) => {
        console.log({ error });
        const message = error.response?.data
          ? error.response?.data?.message
          : "Something went wrong";
        notify(message, "error");
        dispatch({ type: actionTypes.RESEND_VERIFICATION_EMAIL_FAIL });
      });
  };
};

export const getBanks = () => {
  return (dispatch) => {
    PepperestAxios.get(UserAccount.GET_BANKS)
      .then((response) => {
        dispatch(getBanksSuccess(response.data.banks));
      })
      .catch((error) => {});
  };
};

export const getAccountName = (bankCode, accountNo) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ACCOUNT_NAME,
    });
    PepperestAxios.get(
      `${UserAccount.ACCOUNT_NAME_ENQUIRY}?accountNo=${accountNo}&bankCode=${bankCode}`
    )
      .then((response) => {
        dispatch(getAccountNameSuccess(response.data.accountDetails));
        trackEvent("verify_bank_details");
      })
      .catch((error) => {
        console.log({ error });
        const errorMessage = error.response.data
          ? error.response.data.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.GET_ACCOUNT_NAME_FAIL,
          error: errorMessage,
        });
      });
  };
};

export const updateSocialMediaLinkInStore = (userSocialMediaLinkResp) => {
  return {
    type: actionTypes.UPDATE_SOCIAL_LINK_IN_STORE,
    payload: userSocialMediaLinkResp,
  };
};

export const removeSocialMediaLinkInStore = (userSocialMediaLinkResp) => {
  return {
    type: actionTypes.REMOVE_SOCIAL_MEDIA_LINK_IN_STORE,
    payload: userSocialMediaLinkResp,
  };
};

export const addSocialMediaLink = (mediaLink, user, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_SOCIAL_LINK });
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };

    const payload = {
      customerID: user.customerID,
      mediaLink: mediaLink,
    };
    PepperestAxios.post(UserAccount.ADD_SOCIAL_MEDIA_LINK, payload, {
      headers: headers,
    })
      .then((response) => {
        const updatedUserProfile = response.data.userProfile;
        //  Throw an alert first then update the store
        dispatch(setAlert("Social media link added successfully", "success"));
        //  add an action to update the user media link on the store
        dispatch(updateSocialMediaLinkInStore(response.data));
        dispatch(getUserProfileSuccess(updatedUserProfile));
      })
      .catch((err) => {
        dispatch({ type: actionTypes.UPDATE_SOCIAL_LINK_FAIL });
        dispatch(
          setAlert(
            "An error occured while trying to add Social media link",
            "error"
          )
        );
        console.log(
          err,
          "an error occured while trying to add social medial link"
        );
      });
  };
};

export const removeSocialMediaLink = (mediaLinkId, user, token) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };

    const payload = {
      customerID: user.customerID,
      mediaLink_id: mediaLinkId,
    };
    PepperestAxios.post(UserAccount.REMOVE_SOCIAL_MEDIA_LINK, payload, {
      headers: headers,
    })
      .then((response) => {
        const updatedUserProfile = response.data.userProfile;
        dispatch(setAlert("Social media link deleted successfully", "success"));
        //  add an action to update the user media link on the store
        dispatch(removeSocialMediaLinkInStore(response.data));
        dispatch(getUserProfileSuccess(updatedUserProfile));
      })
      .catch((err) => {
        dispatch(
          setAlert(
            "An error occured while trying to remove Social media link",
            "error"
          )
        );
        console.log(
          err,
          "an error occured while trying to add social medial link"
        );
      });
  };
};

export const getUserProfileSuccess = (userProfile) => {
  return {
    type: actionTypes.GET_USER_PROFILE_SUCCESS,
    userProfile: userProfile,
  };
};

export const getBanksSuccess = (banks) => {
  return {
    type: actionTypes.GET_BANKS_SUCCESS,
    banks,
  };
};

export const getAccountNameSuccess = (details) => {
  return {
    type: actionTypes.GET_ACCOUNT_NAME_SUCCESS,
    details,
  };
};

export const getAccountNameComplete = () => {
  return {
    type: actionTypes.GET_ACCOUNT_NAME_COMPLETE,
  };
};

export const updateProfile = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(updatingProfile());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    payLoad.customerID = user.customerID;
    PepperestAxios.post(UserAccount.UPDATE_PROFILE, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const updatedUserProfile = response.data.userProfile;
        dispatch(updatedProfile());
        dispatch(getUserProfileSuccess(updatedUserProfile));
        dispatch(setAlert("Profile updated successfully", "success"));
        mixPanelUserProperty("accounttype_updated", {});
      })
      .catch((error) => {
        error = error.response.data?.message
          ? Object.values(error.response.data?.message).flat().join(" , ")
          : UserAccountErrorMessages.updateProfileFailed;
        dispatch(failedToUpdateProfile(error));
        dispatch(
          setAlert(
            "There was an error updating your profile, please try again later",
            "error",
            getStringHash()
          )
        );
      });
  };
};

export const updatingProfile = () => {
  return {
    type: actionTypes.UPDATING_PROFILE,
  };
};

export const updatedProfile = () => {
  return {
    type: actionTypes.UPDATED_PROFILE,
  };
};

export const failedToUpdateProfile = (error) => {
  return {
    type: actionTypes.FAILED_TO_UPDATE_PROFILE,
    error: error,
  };
};

export const upgradePremium = () => {
  return {
    type: actionTypes.UPGRADE_PREMIUM,
  };
};

export const upgradePremiumRedirect = (tokenization) => {
  return {
    type: actionTypes.PREMIUM_REDIRECT,
    tokenization,
  };
};

export const upgradePremiumFail = (error) => {
  return {
    type: actionTypes.UPGRADE_PREMIUM_FAIL,
    error,
  };
};

export const confirmPremiumPayment = () => {
  return {
    type: actionTypes.CONFIRM_PREMIUM_PAYMENT,
  };
};

export const resetError = () => {
  return {
    type: actionTypes.RESET_ERROR,
  };
};

export const confirmPremiumPaymentSuccess = (paymentDetail) => {
  return {
    type: actionTypes.CONFIRM_PREMIUM_PAYMENT_SUCCESS,
    paymentDetail,
  };
};

export const confirmPremiumPaymentFail = (error) => {
  return {
    type: actionTypes.CONFIRM_PREMIUM_PAYMENT_FAIL,
    error,
  };
};

export const subscribePremium = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(upgradePremium());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
      "Content-Type": "application/json",
    };

    PepperestAxios.post(UserAccount.SUBSCRIBE_PREMIUM, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const { tokenization } = response?.data;
        if(tokenization){

          if (tokenization?.error == 1) {
            toast.error(tokenization?.responseMessage);
          }
  
          dispatch(upgradePremiumRedirect(tokenization));
          trackEvent("upgrade_premium");

        }else if(response?.data?.ResponseStatus === "successful"){
          console.log("wallet response",response)
          dispatch(setAlert(response?.data?.ResponseMessage, "success"));
          trackEvent("upgrade_premium");
          window.location.reload();
        }
       
      })
      .catch((error) => {
        let errorMessage = error?.response?.data
          ? error?.response?.data?.ResponseMessage
          : "There was an error subscrubing to Premium, please try again later";
        dispatch(upgradePremiumFail(errorMessage));
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

export const upgradeToPremium = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(upgradePremium());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
      "Content-Type": "application/json",
    };

    PepperestAxios.post(UserAccount.UPGRADE_PREMIUM, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const { paymentUrl } = response.data;
        trackEvent("upgrade_premium");
        dispatch(upgradePremiumRedirect(paymentUrl));
      })
      .catch((error) => {
        let errorMessage = error?.response?.data?.message
          ? error?.response?.data?.message
          : "There was an error upgrading to Premium, please try again later";
        dispatch(upgradePremiumFail(errorMessage));
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

export const confirmPremiumPaymentStatus = (payLoad) => {
  return (dispatch, getState) => {
    let { token, userInfo } = getState().auth;
    dispatch(confirmPremiumPayment());
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
      "Content-Type": "application/json",
    };

    PepperestAxios.post(UserAccount.CONFIRM_PREMIUM_PAYMENT_STATUS, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const { merchant_sub, ResponseCode, message } = response.data;
        const details = { merchant_sub, ResponseCode, message };
        if (ResponseCode === 200 || ResponseCode === 201) {
          dispatch(confirmPremiumPaymentSuccess(details));
          dispatch(getUserProfile(token, userInfo));
          trackEvent("upgrade_premium_successful");
        }
      })
      .catch((error) => {
        let errorMessage = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "There was an error confirming your Payment, please try again later";
        console.log({ errorMessage });
        dispatch(confirmPremiumPaymentFail(errorMessage));
      });
  };
};

export const updateBankAccount = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(updatingBankAccount());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    payLoad.customerID = user.customerID;
    PepperestAxios.post(UserAccount.UPDATE_BANK_ACCOUNT, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const updatedUserProfile = response.data.userProfile;
        mixPanelUserProperty("bankaccount_updated", {});
        dispatch(updatedBankAccount());
        dispatch(getUserProfileSuccess(updatedUserProfile));
        dispatch(setAlert("Account Details updated successfully", "success"));
        trackEvent("add_bank_details");
      })
      .catch((error) => {
        error = error.response.data?.message
          ? error.response.data?.message
          : UserAccountErrorMessages.updateBankAccountFailed;
        dispatch(failedToUpdateBankAccount(error));
        dispatch(
          setAlert(
            "There was an error updating your Bank details, please try again later",
            "error"
          )
        );
      });
  };
};

export const updatingBankAccount = () => {
  return {
    type: actionTypes.UPDATING_BANK_ACCOUNT,
  };
};

export const updatedBankAccount = () => {
  return {
    type: actionTypes.UPDATED_BANK_ACCOUNT,
  };
};

export const failedToUpdateBankAccount = (error) => {
  return {
    type: actionTypes.FAILED_TO_UPDATE_BANK_ACCOUNT,
    error: error,
  };
};

export const updateUserType = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(updatingUpdateUserType());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    payLoad.customerID = user.customerID;
    PepperestAxios.post(UserAccount.UPDATE_USER_TYPE, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const updatedUserProfile = response.data.userProfile;
        dispatch(updatedUpdateUserType());
        //dispatch  the request  to refresh the user profile
        dispatch(getUserProfile(token, updatedUserProfile));
        dispatch(
          setAlert(
            "User Type Details updated successfully, Sign in again to continue",
            "success",
            getStringHash()
          )
        );
        dispatch(logout());
      })
      .catch((error) => {
        error = error.response.data?.message
          ? Object.values(error.response.data?.message).flat().join(" ")
          : UserAccountErrorMessages.updateUserTypeFailed;
        dispatch(failedToUpdateUpdateUserType(error));
        dispatch(
          setAlert(
            "There was an error updating your User Type, please try again later",
            "error",
            getStringHash()
          )
        );
      });
  };
};

export const updatingUpdateUserType = () => {
  return {
    type: actionTypes.UPDATING_USER_TYPE,
  };
};

export const updatedUpdateUserType = () => {
  return {
    type: actionTypes.UPDATED_USER_TYPE,
  };
};

export const failedToUpdateUpdateUserType = (error) => {
  return {
    type: actionTypes.FAILED_TO_UPDATE_USER_TYPE,
    error: error,
  };
};

export const updateApiSetting = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(updatingApiSetting());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    payLoad.customerID = user.customerID;
    PepperestAxios.post(UserAccount.UPDATE_API_SETTING, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const updatedUserProfile = response.data.userProfile;
        dispatch(updatedApiSetting());
        dispatch(getUserProfileSuccess(updatedUserProfile));
        dispatch(
          setAlert(
            "API settings updated updated successfully",
            "success",
            getStringHash()
          )
        );
      })
      .catch((error) => {
        error = error.response.data?.message
          ? Object.values(error.response.data?.message).flat().join(" ")
          : UserAccountErrorMessages.updateApiSettingFailed;
        dispatch(failedToUpdateApiSetting(error));
        dispatch(
          setAlert(
            "There was an error updating API settings, please try again later",
            "error",
            getStringHash()
          )
        );
      });
  };
};

export const updatingApiSetting = () => {
  return {
    type: actionTypes.UPDATING_API_SETTING,
  };
};

export const updatedApiSetting = () => {
  return {
    type: actionTypes.UPDATED_API_SETTING,
  };
};

export const failedToUpdateApiSetting = (error) => {
  return {
    type: actionTypes.FAILED_TO_UPDATE_API_SETTING,
    error: error,
  };
};

export const updateNotificationSetting = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(updatingNotificationSetting());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    payLoad.customerID = user.customerID;
    PepperestAxios.post(UserAccount.UPDATE_NOTIFICATION_SETTING, payLoad, {
      headers: headers,
    })
      .then((response) => {
        const updatedUserProfile = response.data.userProfile;
        dispatch(updatedNotificationSetting());
        dispatch(getUserProfileSuccess(updatedUserProfile));
        dispatch(
          setAlert(
            "Notifications settings updated successfully",
            "success",
            getStringHash()
          )
        );
      })
      .catch((error) => {
        error = error.response.data?.message
          ? Object.values(error.response.data?.message).flat().join(" ")
          : UserAccountErrorMessages.updateNotificationSettingFailed;
        dispatch(failedToUpdateNotificationSetting(error));
        dispatch(
          setAlert(
            "There was an error updating Notifications settings, please try again later",
            "error",
            getStringHash()
          )
        );
      });
  };
};

export const updatingNotificationSetting = () => {
  return {
    type: actionTypes.UPDATING_NOTIFICATION_SETTING,
  };
};

export const updatedNotificationSetting = () => {
  return {
    type: actionTypes.UPDATED_NOTIFICATION_SETTING,
  };
};

export const failedToUpdateNotificationSetting = (error) => {
  return {
    type: actionTypes.FAILED_TO_UPDATE_NOTIFICATION_SETTING,
    error: error,
  };
};

export const updatePassword = (payLoad, token, user) => {
  return (dispatch) => {
    dispatch(updatingPassword());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    payLoad.merchantID = user.customerID;
    PepperestAxios.post(UserAccount.UPDATE_PASSWORD, payLoad, {
      headers: headers,
    })
      .then((response) => {
        dispatch(updatedPassword());
        dispatch(
          setAlert("Password updated successfully", "success", getStringHash())
        );
      })
      .catch((error) => {
        error = error.response.data?.message
          ? error.response.data?.message
          : UserAccountErrorMessages.updatePasswordFailed;
        dispatch(failedToUpdatePassword(error));
        dispatch(setAlert(error, "error", getStringHash()));
      });
  };
};

export const updatingPassword = () => {
  return {
    type: actionTypes.UPDATING_PASSWORD,
  };
};

export const updatedPassword = () => {
  return {
    type: actionTypes.UPDATED_PASSWORD,
  };
};

export const failedToUpdatePassword = (error) => {
  return {
    type: actionTypes.FAILED_TO_UPDATE_PASSWORD,
    error: error,
  };
};

export const setupStoreComplete = () => {
  return {
    type: actionTypes.SETUP_STORE_COMPLETE,
  };
};

export const setupStore = (payLoad, token, user, data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SETUP_STORE,
    });
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    payLoad.customerID = user.customerID;
    PepperestAxios.post(UserAccount.SETUP_STORE, payLoad, {
      headers: headers,
    })
      .then((response) => {
        if (response?.data?.ResponseCode == 201) {
          trackEvent("setup_store_successful", data);
          dispatch(getUserProfile(token, user));
          dispatch({
            type: actionTypes.SETUP_STORE_SUCCESS,
          });
          dispatch(setAlert(response?.data?.ResponseMessage, "success"));
          mixPanelUserProperty("store setup complete", {});
        } else {
          const message = response?.data?.ResponseMessage;
          dispatch(setAlert(message, "error"));
          trackEvent("setup_store_fail", data);
          dispatch({
            type: actionTypes.SETUP_STORE_FAIL,
            error: message,
          });
        }
      })
      .catch((error) => {
        let message = error?.response?.data
          ? error.response.data?.message
          : UserAccountErrorMessages.setupStore;
        dispatch({
          type: actionTypes.SETUP_STORE_FAIL,
          error: message,
        });
        dispatch(setAlert(message, "error", getStringHash()));
        trackEvent("setup_store_fail", data);
      });
  };
};

export const unsubScribePremium = () => {
  return (dispatch, getState) => {
    let { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.UNSUBSCRIBE_PREMIUM });
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
      "Content-Type": "application/json",
    };

    PepperestAxios.post(
      UserAccount.UNSUBSCRIBE_PREMIUM,
      {},
      {
        headers: headers,
      }
    )
      .then((response) => {
        const { ResponseMessage } = response.data;
        dispatch(getUserProfile(token, userInfo));
        notify(ResponseMessage, "success");
        dispatch({ type: actionTypes.UNSUBSCRIBE_PREMIUM_SUCCESS });
      })
      .catch((error) => {
        console.log({ error });
        let errorMessage = error.response?.data
          ? error.response?.data?.message
          : "We couldn't make this happen, try again";
        dispatch({
          type: actionTypes.UNSUBSCRIBE_PREMIUM_FAIL,
          error: errorMessage,
        });
        dispatch(setAlert(errorMessage, "error", getStringHash()));
      });
  };
};

export const getBillingHistory = () => {
  return (dispatch, getState) => {
    let { token, user } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
      "Content-Type": "application/json",
    };

    PepperestAxios.get(UserAccount.BILLING_HISTORY, { headers })
      .then((response) => {
        const { billing_history } = response.data;
        dispatch({
          type: actionTypes.GET_BILLING_HISTORY_SUCCESS,
          billingHistory: {
            data: billing_history.data,
            meta: billing_history.meta,
          },
        });
      })
      .catch((error) => {
        console.log({ error });
        dispatch({ type: actionTypes.GET_BILLING_HISTORY_FAIL });
      });
  };
};

export const customizeStore = (payLoad) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CUSTOMIZING_STORE,
    });
    const { userInfo, token } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    payLoad.customerID = userInfo.customerID;
    PepperestAxios.post(UserAccount.CUSTOMIZE_STORE, payLoad, {
      headers: headers,
    })
      .then((response) => {
        if (response?.data?.ResponseCode == 201) {
          dispatch({
            type: actionTypes.CUSTOMIZE_STORE_SUCCESS,
          });
          dispatch(getUserProfile(token, userInfo));
          dispatch(setAlert(response?.data?.ResponseMessage, "success"));
        } else {
          const message = response?.data?.ResponseMessage;
          dispatch(setAlert(message, "error"));
          dispatch({
            type: actionTypes.CUSTOMIZE_STORE_FAIL,
          });
        }
      })
      .catch((error) => {
        let message = error?.response?.data
          ? error.response.data?.message
          : "We couldn't make this happen at the moment";
        dispatch({
          type: actionTypes.CUSTOMIZE_STORE_FAIL,
          error: message,
        });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};

export const customizeStoreUrl = (payLoad) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CUSTOMIZING_STORE,
    });
    const { userInfo, token } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    payLoad.customerID = userInfo.customerID;
    PepperestAxios.post(UserAccount.CUSTOMIZE_STORE_URL, payLoad, {
      headers: headers,
    })
      .then((response) => {
        if (response?.data?.ResponseCode == 201) {
          dispatch({
            type: actionTypes.CUSTOMIZE_STORE_SUCCESS,
          });
          dispatch(getUserProfile(token, userInfo));
          dispatch(setAlert(response?.data?.ResponseMessage, "success"));
        } else {
          const message = response?.data?.ResponseMessage;
          dispatch(setAlert(message, "error"));
          dispatch({
            type: actionTypes.CUSTOMIZE_STORE_FAIL,
            error: message,
          });
        }
      })
      .catch((error) => {
        let message = error?.response?.data
          ? error.response.data?.message
          : "We couldn't make this happen at the moment";
        dispatch({
          type: actionTypes.CUSTOMIZE_STORE_FAIL,
          error: message,
        });
        dispatch(setAlert(message, "error", getStringHash()));
      });
  };
};

const customizeStoreComplete = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CUSTOMIZE_STORE_COMPLETE });
  };
};

export const getPricingPlans = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_PRICING });
    PepperestAxios.get(UserAccount.PRICING_PLANS)
      .then((response) => {
        const { subscription } = response?.data;
        console.log({ subscription: subscription.data });
        dispatch({
          type: actionTypes.GET_PRICING_SUCCESS,
          subscription: subscription?.data,
        });
      })
      .catch((error) => {
        const message = error.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not load pricing plan";
        dispatch({ type: actionTypes.GET_PRICING_FAIL, error: message });
      });
  };
};
