import React, { useCallback, useState, useEffect } from "react";
import ModalComponent from "../ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineFileUpload } from "react-icons/md";
import { FileUploader } from "react-drag-drop-files";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import InputField from "components/blocks/InputField";
import { disputeCategories } from "libs/constants";
import { raiseDispute, raiseDisputeComplete } from "store/actions/orders";

const RaiseDisputeModal = ({ order, setIsDispute, isDispute }) => {
  const [files, setFiles] = useState(null);
  const { raisingDispute, disputeComplete, disputeError } = useSelector(
    (state) => state.orders
  );
  const [imageError, setImageError] = useState(false);
  const fileTypes = ["png", "jpg", "jpeg", "pdf", "doc", "docx"];
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (files) => {
      if (files) {
        setFiles(files);
        console.log({ files });
        setImageError("");
      }
    },
    [files]
  );
  const removeFile = (index) => {
    const updatedFile = [...files];
    updatedFile.splice(index, 1);
    setFiles(updatedFile);
  };

  const disputeSchema = yup.object().shape({
    dispute_category: yup.string().required("required"),
    dispute_description: yup.string().required("required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(disputeSchema),
  });

  const handleUpload = (data) => {
    if (!files) {
      setImageError("Choose a file to continue");
      return;
    }
    let formData = new FormData();
    formData.append("orderID", order.orderId);
    formData.append("dispute_category", data.dispute_category);
    formData.append("dispute_description", data.dispute_description);
    Array.from(files).map((file, index) => {
      formData.append(`dispute_files[${index}]`, file);
    });
    dispatch(raiseDispute(formData));
  };

  useEffect(() => {
    if (disputeComplete) {
      setIsDispute(false);
      dispatch(raiseDisputeComplete());
    }
  }, [disputeComplete]);

  return (
    <ModalComponent show={isDispute} centered size="md">
      <main className="upload__prof">
        <div className="modal__component">
          <div className="modal__title">Report Dispute</div>
          <div className="modal__content ">
            <form onSubmit={handleSubmit(handleUpload)} className="w-100">
              <section>
                <p className="error__message">{disputeError}</p>
                <div className="form__wrapper">
                  <InputField
                    require={true}
                    id="dispute_category"
                    label="Dispute Category"
                    name="dispute_category"
                    placeHolder="Dispute category"
                    register={register}
                    options={disputeCategories}
                    error={errors.dispute_category?.message}
                    type="select"
                  />
                </div>
                <div className="form__wrapper">
                  <InputField
                    require={true}
                    id="dispute_description"
                    label="Dispute Description"
                    name="dispute_description"
                    placeHolder="Dispute Description"
                    register={register}
                    error={errors.dispute_description?.message}
                    type="textarea"
                  />
                </div>

                {/* <div className="row row__wrapper">
                  <div className="col">
                    <div className="form__wrapper">
                      <InputField
                        require={true}
                        id="cost"
                        label="Cost"
                        name="cost"
                        defaultValue={order?.cost}
                        placeHolder="Cost"
                        register={register}
                        error={errors.cost?.message}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form__wrapper">
                      <InputField
                        require={true}
                        id="end_date"
                        label="Due Date"
                        name="end_date"
                        placeHolder="Due Date"
                        defaultValue={formatDateString(order?.date)}
                        register={register}
                        error={errors.end_date?.message}
                        type="date"
                      />
                    </div>
                  </div>
                </div> */}
                <label className="form__label">Upload dispute images</label>
                <section className="upload__wrapper">
                  <div
                    className={`form__wrapper border__wrapper ${
                      imageError ? "border-danger" : ""
                    }`}
                  >
                    <FileUploader
                      handleChange={handleChange}
                      multiple={true}
                      types={fileTypes}
                    >
                      <div className="file__uploader__wrapper ">
                        <MdOutlineFileUpload color="#5E60CE" size={30} />
                        <div>
                          <p className="title">
                            Drag and drop an image, or{" "}
                            <span className="upload__button">Browse</span>
                          </p>
                          <p className="resolution__text">
                            High resolution images (png,jpg,gif)
                          </p>
                        </div>
                      </div>
                    </FileUploader>
                  </div>
                </section>
                <div className="selected__files">
                  {files ? (
                    <>
                      {Array.from(files).map((file, index) => (
                        <div className="file" key={index}>
                          <p>{file?.name}</p>
                          <span
                            className="remove__file"
                            onClick={() => removeFile(index)}
                          >
                            x
                          </span>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
                <div className="error__message">{imageError && imageError}</div>

                <div className="modal__control">
                  <button
                    disabled={raisingDispute}
                    type="button"
                    className="button button--purple-outline px-4"
                    onClick={() => setIsDispute(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button button--purple px-4"
                    type="submit"
                    disabled={raisingDispute}
                  >
                    Submit
                  </button>
                </div>
              </section>
            </form>
          </div>
        </div>
      </main>
    </ModalComponent>
  );
};

export default RaiseDisputeModal;
